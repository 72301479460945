// @import './fonts';
@import './variables';
@import './ardrop';
@import './media';
@import './default';
@import './form';
@import './modal';
@import './header';
@import './menu';
@import './footer';
@import './billboard';
@import './home-cards';
@import './home-service';
@import './head-page';
@import './card-pool';
@import './statistics';
@import './about';
@import './workshop';
@import './auction';
@import './exhibition-hall';
@import './form-app';
@import './tooltip';
@import './nouislider';
@import './claim-reward';
@import './transction-submitted';
@import './emoji';
@import './voter';
@import './breadcrumbs';
@import './tab';
@import './tabs';
@import './account';
@import './hashtag';
@import './artwork-list';
@import './data-price';
@import './swiper';
@import './submit-artwork';
@import './publish-artwork';
@import './available-artworks';
@import './slick';
@import './banner';
@import './pool';
@import './pools-banner';
@import './pools-header';
@import './pools-text-header';
@import './pools_card';
@import './pools_detail';
@import './pools-index';
@import './pooltab';
@import './pools-join';
@import './pools-type';
@import './write-information';
@import './menumask';
@import './no-connect';
@import './content';

>>>.ant-modal-content {
    background: black !important;
}
>>>.ant-modal-body {
    font-size: 60px;
}