.menumask {
  position: relative;
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(34, 41, 47, 0.6);
  left: 0;
  top: 0;
  touch-action: none;
  z-index: 99;
  &_left {
    width: 20%;
    height: 100%;
  }
  &_right {
    top: 0;
    width: 80%;
    height: 100%;
    background: #f2f0eb;
    position: absolute;
    animation: mask_right 1s;
    right: 0;
  }
  &_download-pdf {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('../icon/file-shield-fill@2x.png') no-repeat;
    background-size: 100% 100%;
  }
  &_guide {
    font-size: 14px;
    font-weight: 600;
    color: #22292f;
    &:hover {
      color: #0f8c20;
    }
  }
  &_ht-balance {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    height: 38px;
    background: #f2f0eb;
    border: 1px solid #0f8c20;
    border-radius: 0px 0px 5px 5px;
    span {
      margin-right: 8px;
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url('../icon/HT@2x.png') no-repeat;
      background-size: 100% 100%;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
      color: #22292f;
    }
  }
  &_language {
    position: absolute;
    bottom: 18px;
    left: 28px;
  }
  &_header-account {
    display: flex;
    justify-content: inherit;
    align-items: center;
    position: relative;
    color: #000;
    transition: 1s;
    // width: 100%;
    height: 38px;
    padding: 0 16px;
    background: #d4e9e2;
    border-radius: 5px;

    .balance {
      width: 160px;
      text-align: right;

      p {
        color: #fff;
        margin-right: 16px;
      }
    }

    .address {
      max-width: 153px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 24px;
        margin-left: 4px;
      }
    }

    .point {
      width: 16px;
      height: 16px;
      background: var(--yellow);
      border-radius: 50%;
      margin-left: 16px;
    }
  }
  @keyframes mask_right {
    0% {
      right: -80%;
    }
    100% {
      right: 0%;
    }
  }
  &_nav {
    padding: 60px 40px 40px;
    width: 100%;
    height: 100%;
  }
  &_list {
    padding-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    border-bottom: 1px solid #ebe9e3;
  }
  &_item {
    margin: 15px 0;
  }
  &_link {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    &:hover {
      color: rgba(15, 140, 32, 1);
    }
  }
  &__hot {
    position: absolute;
    top: -22px;
    right: -34px;
    width: 46px;
    height: 26px;
    background: url('../icon/HOT@2x.png') no-repeat;
    background-size: 100% 100%;
  }
  .active {
    color: #0f8c20;
  }
}
@media #{$desktop} {
  .menumask {
    display: none;
  }
}
@media #{$mdWidth} {
  .menumask {
    display: block;
  }
}
@media #{$smWidth} {
  .menumask {
    display: block;
  }
}
@media #{$xsWidth} {
  .menumask {
    display: block;
  }
}
