.pools_detail {
  width: 100%;
  // background: #fff;
}
@media #{$desktop} {
  .pools_detail {
    // margin-top: 60px;
    padding-bottom: 176px;
    &_btn_box {
      margin: 0 auto;
      padding: 70px 0;
      text-align: center;
    }
    &_btn {
      display: inline-block;
      width: 128px;
      height: 48px;
      font-size: 16px;
      color: #22292f;
      font-weight: 600;
      line-height: 48px;
      border-radius: 24px;
      background: #f2f0eb;
      &:hover {
        color: #fff;
      }
      &:last-child {
        margin-left: 24px;
        &:hover {
          color: #1890ff;
        }
      }
    }
    &_btn_active {
      color: #fff;
      background: #1890ff;
      box-shadow: 0px 4px 20px 0px rgba(15, 140, 32, 0.3);
      &:hover {
        color: #fff;
        background: #1890ff;
      }
    }

    &_btn_disable {
      color: #fff;
      background: #999;
      box-shadow: 0px 4px 20px 0px rgba(#999, 0.3);
    }
    &_record {
      position: relative;
      margin: 0 auto 0px;
      padding: 0 50px 30px;
      width: 640px;
      min-height: 164px;
      background: #f2f0eb;
      border-radius: 10px;
      .mask_layer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 0 30px;
        width: 640px;
        height: 164px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 164px;
        text-align: center;
        background: rgba(34, 41, 47, 0.7);
        border-radius: 10px;
      }
    }
    &_record_tab {
      a {
        position: relative;
        margin: 0 36px 22px 0;
        height: 60px;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        line-height: 60px;
        &:hover {
          color: #1890ff;
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: #1890ff;
          }
        }
      }
      a.active {
        color: #1890ff;
        &:after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background: #1890ff;
        }
      }
    }
    &_record_title {
      width: 100%;
      thead {
        tr {
          td {
            font-size: 12px;
            font-weight: 400;
            color: #7a7f82;
            line-height: 28px;
            &:last-child {
              text-align: right;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 14px;
            font-weight: 500;
            color: #22292f;
            line-height: 26px;
            &:last-child {
              text-align: right;
            }
          }
          .pools_detail_record_btn {
            display: inline-block;
            padding: 0 8px;
            font-size: 14px;
            color: #ffffff;
            line-height: 24px;
            background: #1890ff;
            border-radius: 12px;
            text-align: right;
          }
        }
      }
    }
    &_content {
      width: 1100px;
      margin: 0 auto;
    }
    &_content_tab {
      height: 60px;
      line-height: 60px;
      border-bottom: 2px solid #ebe9e3;
      a {
        position: relative;
        display: inline-block;
        font-size: 24px;
        font-weight: 500;
        color: #060417;
        &:last-child {
          // margin-left: 40px;
        }
        &:hover {
          color: #7a7f82;
        }
        &.active {
          color: #22292f;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background-color: #1890ff;
          }
        }
      }
    }
    &_table_box {
      margin: 40px auto 0;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      justify-content: center;
    }
    &_table {
      width: 80%;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      tr {
        height: 60px;
        border-bottom: 1px solid #ebe9e3;
        th {
          font-size: 14px;
          font-weight: bold;
          color: #22292f;
          line-height: 60px;
          text-align: left;
        }
        td {
          font-size: 14px;
          color: #7a7f82;
          line-height: 60px;
          p {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            justify-content: space-between;
            margin: 0;
          }
          span:last-child {
            text-align: right;
            color: #22292f;
          }
        }
      }
      tbody {
        tr {
          &:last-child {
            border-bottom: none;
          }
        }
      }
      &:first-child {
        th {
          padding: 0 50px 0 30px;
        }
        td {
          padding: 0 50px 0 30px;
        }
      }
      &:last-child {
        border-left: none;
        th {
          padding: 0 30px 0 50px;
        }
        td {
          padding: 0 30px 0 50px;
        }
      }
    }
    &_table__list {
      th,
      td {
        padding: 0 10px;
        &:first-child {
          padding: 0 10px 0 30px;
        }
        &:last-child {
          padding: 0 36px 0 10px;
          text-align: right;
        }
      }
      tbody {
        tr {
          &:hover {
            cursor: pointer;
            background-color: #f5fbf9;
          }
          .pools_detail_table__confirm {
            display: inline-block;
            padding: 0 8px;
            font-size: 14px;
            color: #ffffff;
            line-height: 24px;
            background: #1890ff;
            border-radius: 12px;
          }
        }
      }
    }
    &_content_link {
      .no_link {
        &:hover {
          cursor: text;
          color: #22292f;
        }
      }
      a {
        margin-top: 20px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #22292f;
        line-height: 24px;
        &:hover {
          color: #1890ff;
        }
        img {
          margin-right: 8px;
          display: inline-block;
          width: 24px;
          height: 24px;
        }
        svg {
          margin-right: 8px;
        }
        &:first-child {
          margin-top: 40px;
        }
        &:last-child {
          margin-top: 30px;
        }
      }
    }
    &_coin__list {
      display: inline-block;
      padding: 0 30px;
      line-height: 40px;
      color: #22292f;
      font-size: 14px;
      font-weight: 500;
      background: #f2f0eb;
      border-radius: 20px;
      margin-right: 20px;
      &:hover {
        color: #1890ff;
      }
    }
    &_coin__list_active {
      color: #fff;
      background: #1890ff;
      &:hover {
        color: #fff;
      }
    }
  }
  .pools_detail_index {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    margin-top: 50px;
    justify-content: space-around;
    .pools-i {
      width: 180px;
      height: 180px;
      background: #1E49A9;
      border: 1px solid #FFFFFF;
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #D3E0FF;
      }
      .number {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 16px;
      }
    }
  }
  .raise-address-1 {
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
    img {
      width: 160px;
      height: 60px;
    }
  }
  .raise-address-2 {
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
    img {
      width: 300px;
      height: 60px;
    }
  }
}
@media #{$mdWidth} {
  .pools_detail {
    // margin-top: 60px;
    padding-bottom: 176px;
    &_btn_box {
      margin: 0 auto;
      padding: 30px 0;
      text-align: center;
    }
    &_btn {
      display: inline-block;
      width: 128px;
      height: 48px;
      font-size: 16px;
      color: #22292f;
      font-weight: 600;
      line-height: 48px;
      border-radius: 24px;
      background: #f2f0eb;
      &:hover {
        color: #fff;
      }
      &:last-child {
        margin-left: 24px;
        &:hover {
          color: #1890ff;
        }
      }
    }
    &_btn_active {
      color: #fff;
      background: #1890ff;
      box-shadow: 0px 4px 20px 0px rgba(15, 140, 32, 0.3);
      &:hover {
        color: #fff;
        background: #00b900;
      }
    }

    &_btn_disable {
      color: #fff;
      background: #999;
      box-shadow: 0px 4px 20px 0px rgba(#999, 0.3);
    }
    &_record {
      position: relative;
      margin: 0 auto 0px;
      padding: 0 50px 30px;
      width: 640px;
      min-height: 164px;
      background: #f2f0eb;
      border-radius: 10px;
      .mask_layer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 0 30px;
        width: 640px;
        height: 164px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 164px;
        text-align: center;
        background: rgba(34, 41, 47, 0.7);
        border-radius: 10px;
      }
    }
    &_record_tab {
      a {
        position: relative;
        margin: 0 36px 22px 0;
        height: 60px;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        line-height: 60px;
        &:hover {
          color: #1890ff;
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: #1890ff;
          }
        }
      }
      a.active {
        color: #1890ff;
        &:after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background: #1890ff;
        }
      }
    }
    &_record_title {
      width: 100%;
      thead {
        tr {
          td {
            font-size: 12px;
            font-weight: 400;
            color: #7a7f82;
            line-height: 28px;
            &:last-child {
              text-align: right;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 14px;
            font-weight: 500;
            color: #22292f;
            line-height: 26px;
            &:last-child {
              text-align: right;
            }
          }
          .pools_detail_record_btn {
            display: inline-block;
            padding: 0 8px;
            font-size: 14px;
            color: #ffffff;
            line-height: 24px;
            background: #1890ff;
            border-radius: 12px;
            text-align: right;
          }
        }
      }
    }
    &_content {
      width: 100%;
      margin: 0 auto;
    }
    &_content_tab {
      height: 60px;
      line-height: 60px;
      border-bottom: 2px solid #ebe9e3;
      a {
        position: relative;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        &:last-child {
          margin-left: 40px;
        }
        &:hover {
          color: #7a7f82;
        }
        &.active {
          color: #22292f;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background-color: #1890ff;
          }
        }
      }
    }
    &_table_box {
      margin: 40px auto 0;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      justify-content: center;
      border-radius: 5px;
    }
    &_table {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      tr {
        height: 60px;
        border-bottom: 1px solid #ebe9e3;
        th {
          font-size: 14px;
          font-weight: bold;
          color: #22292f;
          line-height: 60px;
          text-align: left;
        }
        td {
          font-size: 14px;
          color: #7a7f82;
          line-height: 60px;
          p {
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            justify-content: space-between;
            margin: 0;
          }
          span:last-child {
            text-align: right;
            color: #22292f;
          }
        }
      }
      &:first-child {
        th {
          padding: 0 50px 0 30px;
        }
        td {
          padding: 0 50px 0 30px;
        }
      }
      &:last-child {
        border-left: none;
        th {
          padding: 0 30px 0 50px;
        }
        td {
          padding: 0 30px 0 50px;
        }
      }
    }
    &_table__list {
      th,
      td {
        padding: 0 10px;
        &:first-child {
          padding: 0 10px 0 30px;
        }
        &:last-child {
          padding: 0 36px 0 10px;
          text-align: right;
        }
      }
      tbody {
        tr {
          &:hover {
            cursor: pointer;
            background-color: #f5fbf9;
          }
          .pools_detail_table__confirm {
            display: inline-block;
            padding: 0 8px;
            font-size: 14px;
            color: #ffffff;
            line-height: 24px;
            background: #1890ff;
            border-radius: 12px;
          }
        }
      }
    }
    &_content_link {
      .no_link {
        &:hover {
          cursor: text;
          color: #22292f;
        }
      }
      a {
        margin-top: 20px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #22292f;
        line-height: 24px;
        &:hover {
          color: #1890ff;
        }
        img {
          margin-right: 8px;
          display: inline-block;
          width: 24px;
          height: 24px;
        }
        svg {
          margin-right: 8px;
        }
        &:first-child {
          margin-top: 40px;
        }
        &:last-child {
          margin-top: 30px;
        }
      }
    }
    &_coin__list {
      display: inline-block;
      padding: 0 30px;
      line-height: 40px;
      color: #22292f;
      font-size: 14px;
      font-weight: 500;
      background: #f2f0eb;
      border-radius: 20px;
      margin-right: 20px;
      &:hover {
        color: #1890ff;
      }
    }
    &_coin__list_active {
      color: #fff;
      background: #1890ff;
      &:hover {
        color: #fff;
      }
    }
  }
  .pools_detail_index {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    .pools-i {
      width: 90px;
      height: 90px;
      background: #1E49A9;
      border: 1px solid #FFFFFF;
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #D3E0FF;
      }
      .number {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 16px;
      }
    }
  }
  .raise-address {
    margin-top: 50px;
    img {
      width: 100px;
      height: 40px;
    }
  }
}

@media #{$smWidth} {
  .pools_detail {
    padding-bottom: 40px;
    &_btn_box {
      margin: 0 auto;
      padding: 30px 0;
      text-align: center;
    }
    &_btn {

      width: 84%;
      margin: 0 auto;
      background: rgba(90, 141, 255, 0.4);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 36px;
      display: inline-block;
      // width: 128px;
      height: 36px;
      font-size: 16px;
      color: #3C26E8;
      font-weight: 600;
      line-height: 36px;
      border-radius: 24px;
      &:hover {
        color: #fff;
      }
      &:last-child {
        // margin-left: 24px;
        &:hover {
          color: #1890ff;
        }
      }
    }
    &_btn_active {
      color: #fff;
      background: #1890ff;
      box-shadow: 0px 4px 20px 0px rgba(15, 140, 32, 0.3);
      &:hover {
        color: #fff;
        background: #00b900;
      }
    }

    &_btn_disable {
      color: #fff;
      background: #999;
      box-shadow: 0px 4px 20px 0px rgba(#999, 0.3);
    }
    &_record {
      position: relative;
      margin: 0 auto 20px;
      padding: 0 18px 12px;
      width: 320px;
      min-height: 164px;
      background: #f2f0eb;
      border-radius: 10px;
      .mask_layer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 0 20px;
        width: 320px;
        height: 172px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 164px;
        text-align: center;
        background: rgba(34, 41, 47, 0.7);
        border-radius: 10px;
      }
    }
    &_record_tab {
      a {
        position: relative;
        margin: 0 36px 18px 0;
        height: 60px;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        line-height: 60px;
        &:hover {
          color: #1890ff;
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: #1890ff;
          }
        }
      }
      a.active {
        color: #1890ff;
        &:after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background: #1890ff;
        }
      }
    }
    &_record_title {
      width: 100%;
      thead {
        tr {
          td {
            font-size: 12px;
            font-weight: 400;
            color: #7a7f82;
            line-height: 28px;
            &:last-child {
              text-align: right;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 14px;
            font-weight: 500;
            color: #22292f;
            line-height: 26px;
            &:last-child {
              text-align: right;
            }
          }
          .pools_detail_record_btn {
            display: inline-block;
            padding: 0 8px;
            font-size: 14px;
            color: #ffffff;
            line-height: 24px;
            background: #1890ff;
            border-radius: 12px;
            text-align: right;
          }
        }
      }
    }
    &_content {
      width: 320px;
      margin: 0 auto;
    }
    &_content_tab {
      // height: 60px;
      line-height: 50px;
      border-bottom: 2px solid #ebe9e3;
      a {
        position: relative;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #060417;
        border-bottom: 2px solid #3C26E8;
        &:last-child {
          // margin-left: 40px;
        }
        &:hover {
          color: #7a7f82;
        }
        &.active {
          color: #22292f;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background-color: #1890ff;
          }
        }
      }
    }
    &_table_box {
      margin: 20px auto 0;
    }
    &_table {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      table-layout: fixed;
      word-break: break-all;
      thead {
        tr {
          height: 60px;
          line-height: 60px;
        }
      }
      tbody {
        tr {
          height: 50px;
          line-height: 50px;
        }
      }
      tr {
        border-bottom: 1px solid #ebe9e3;
        th {
          padding: 0 16px 0 10px;
          font-size: 14px;
          font-weight: bold;
          color: #22292f;
          line-height: 18px;
          text-align: left;
        }
        td {
          padding: 0 16px 0 10px;
          font-size: 14px;
          // color: #7a7f82;
          color: #060417;
          line-height: 18px;
          p {
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            justify-content: space-between;
            margin: 0;
          }
          span:last-child {
            text-align: right;
            // color: #22292f;
            color: #050127;
          }
        }
      }
    }
    &_table:last-child {
      margin-top: 20px;
    }
    &_table__list {
      th,
      td {
        padding: 0 10px;
        word-wrap: break-word;
        &:last-child {
          text-align: right;
        }
      }
      tbody {
        tr {
          &:hover {
            cursor: pointer;
            background-color: #f5fbf9;
          }
          .pools_detail_table__confirm {
            display: inline-block;
            padding: 0 8px;
            font-size: 14px;
            color: #ffffff;
            line-height: 24px;
            background: #1890ff;
            border-radius: 12px;
          }
        }
      }
    }
    &_content_link {
      .no_link {
        &:hover {
          cursor: text;
          color: #22292f;
        }
      }
      a {
        margin-top: 20px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #22292f;
        line-height: 24px;
        &:hover {
          color: #1890ff;
        }
        img {
          margin-right: 8px;
          display: inline-block;
          width: 24px;
          height: 24px;
        }
        svg {
          margin-right: 8px;
        }
        &:first-child {
          margin-top: 40px;
        }
        &:last-child {
          margin-top: 30px;
        }
      }
    }
    &_coin__list {
      display: inline-block;
      padding: 0 30px;
      line-height: 40px;
      color: #22292f;
      font-size: 14px;
      font-weight: 500;
      background: #f2f0eb;
      border-radius: 20px;
      margin-right: 20px;
      &:hover {
        color: #1890ff;
      }
    }
    &_coin__list_active {
      color: #fff;
      background: #1890ff;
      &:hover {
        color: #fff;
      }
    }
  }
  .pools_detail_index {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    .pools-i {
      width: 90px;
      height: 90px;
      // background: #1E49A9;
      // border: 1px solid #FFFFFF;
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      background: linear-gradient(0deg, #0ABEA3 0%, #23D2B9 64%);
      border-radius: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 30px;
      justify-content: center;
      .title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        text-align: center;
        word-wrap: break-word;
        width: 90%;
        color: #14144E;
        opacity: 0.8;
      }
      .number {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 16px;
      }
    }
  }
  .raise-address-1 {
    margin-top: 20px;
    padding-left: 20px;
    img {
      width: 100px;
      height: 40px;
    }
  }
  .raise-address-2 {
    margin-top: 20px;
    padding-left: 20px;
    img {
      width: 200px;
      height: 40px;
    }
  }
}
@media #{$xsWidth} {
  .pools_detail {
    padding-bottom: 40px;
    &_btn_box {
      margin: 0 auto;
      padding: 30px 0;
      text-align: center;
    }
    &_btn {
      display: inline-block;
      width: 128px;
      height: 48px;
      font-size: 16px;
      color: #22292f;
      font-weight: 600;
      line-height: 48px;
      border-radius: 24px;
      background: #f2f0eb;
      &:hover {
        color: #fff;
      }
      &:last-child {
        margin-left: 24px;
        &:hover {
          color: #1890ff;
        }
      }
    }
    &_btn_active {
      color: #fff;
      background: #1890ff;
      box-shadow: 0px 4px 20px 0px rgba(15, 140, 32, 0.3);
      &:hover {
        color: #fff;
        background: #00b900;
      }
    }

    &_btn_disable {
      color: #fff;
      background: #999;
      box-shadow: 0px 4px 20px 0px rgba(#999, 0.3);
    }
    &_record {
      position: relative;
      margin: 0 auto 20px;
      padding: 0 18px 12px;
      width: 320px;
      min-height: 164px;
      background: #f2f0eb;
      border-radius: 10px;
      .mask_layer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 0 20px;
        width: 320px;
        height: 172px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 164px;
        text-align: center;
        background: rgba(34, 41, 47, 0.7);
        border-radius: 10px;
      }
    }
    &_record_tab {
      a {
        position: relative;
        margin: 0 36px 18px 0;
        height: 60px;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        line-height: 60px;
        &:hover {
          color: #1890ff;
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: #1890ff;
          }
        }
      }
      a.active {
        color: #1890ff;
        &:after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background: #1890ff;
        }
      }
    }
    &_record_title {
      width: 100%;
      thead {
        tr {
          td {
            font-size: 12px;
            font-weight: 400;
            color: #7a7f82;
            line-height: 28px;
            &:last-child {
              text-align: right;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 14px;
            font-weight: 500;
            color: #22292f;
            line-height: 26px;
            &:last-child {
              text-align: right;
            }
          }
          .pools_detail_record_btn {
            display: inline-block;
            padding: 0 8px;
            font-size: 14px;
            color: #ffffff;
            line-height: 24px;
            background: #1890ff;
            border-radius: 12px;
            text-align: right;
          }
        }
      }
    }
    &_content {
      width: 320px;
      margin: 0 auto;
    }
    &_content_tab {
      height: 60px;
      line-height: 60px;
      border-bottom: 2px solid #ebe9e3;
      a {
        position: relative;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        &:last-child {
          margin-left: 40px;
        }
        &:hover {
          color: #7a7f82;
        }
        &.active {
          color: #22292f;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background-color: #1890ff;
          }
        }
      }
    }
    &_table_box {
      margin: 20px auto 0;
    }
    &_table {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      table-layout: fixed;
      word-break: break-all;
      thead {
        tr {
          height: 60px;
          line-height: 60px;
        }
      }
      tbody {
        tr {
          height: 50px;
          line-height: 50px;
        }
      }
      tr {
        border-bottom: 1px solid #ebe9e3;
        th {
          padding: 0 16px 0 10px;
          font-size: 14px;
          font-weight: bold;
          color: #22292f;
          line-height: 18px;
          text-align: left;
        }
        td {
          padding: 0 16px 0 10px;
          font-size: 14px;
          color: #7a7f82;
          line-height: 18px;
          p {
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            justify-content: space-between;
            margin: 0;
          }
          span:last-child {
            text-align: right;
            color: #22292f;
          }
        }
      }
    }
    &_table:last-child {
      margin-top: 20px;
    }
    &_table__list {
      th,
      td {
        padding: 0 10px;
        word-wrap: break-word;
        &:last-child {
          text-align: right;
        }
      }
      tbody {
        tr {
          &:hover {
            cursor: pointer;
            background-color: #f5fbf9;
          }
          .pools_detail_table__confirm {
            display: inline-block;
            padding: 0 8px;
            font-size: 14px;
            color: #ffffff;
            line-height: 24px;
            background: #1890ff;
            border-radius: 12px;
          }
        }
      }
    }
    &_content_link {
      .no_link {
        &:hover {
          cursor: text;
          color: #22292f;
        }
      }
      a {
        margin-top: 20px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #22292f;
        line-height: 24px;
        &:hover {
          color: #1890ff;
        }
        img {
          display: inline-block;
          margin-right: 8px;
          width: 24px;
          height: 24px;
        }
        svg {
          margin-right: 8px;
        }
        &:first-child {
          margin-top: 40px;
        }
        &:last-child {
          margin-top: 30px;
        }
      }
    }
    &_coin__list {
      display: inline-block;
      padding: 0 30px;
      line-height: 40px;
      color: #22292f;
      font-size: 14px;
      font-weight: 500;
      background: #f2f0eb;
      border-radius: 20px;
      margin-right: 20px;
      &:hover {
        color: #1890ff;
      }
    }
    &_coin__list_active {
      color: #fff;
      background: #1890ff;
      &:hover {
        color: #fff;
      }
    }
  }
  .pools_detail_index {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    .pools-i {
      width: 90px;
      height: 90px;
      background: #1E49A9;
      border: 1px solid #FFFFFF;
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #D3E0FF;
      }
      .number {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 16px;
      }
    }
  }
  .raise-address {
    margin-top: 50px;
    img {
      width: 100px;
      height: 40px;
    }
  }
}
