.banner {
  width: 100%;
}
@media #{$desktop} {
  .banner {
    width: 1080px;
    margin: 0 auto;
    &_img {
      display: block;
      margin: 0 auto;
      width: 30%;
      height: 270px;
      background-image: url('../image/weBanner.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    &_text {
      font-size: 44px;
      font-weight: 600;
      color: #22292f;
      line-height: 62px;
      margin-top: 50px;
    }
    img {
      width: 160px;
      height: 60px;
    }
    &_text-title {
      display: none;
    }
    &_dec {
      width: 100%;
      font-size: 18px;
      font-weight: 300;
      color: #7a7f82;
      line-height: 36px;
      margin-top: 20px;
    }
    &_link {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 52px;
      span {
        font-size: 20px;
        font-family: PTMono-Regular, PTMono !important;
        font-weight: 400;
        color: #22292f;
        line-height: 20px;
      }
      a {
        display: inline-block;
        background-image: url('../icon/huobi-logo.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 220px;
        height: 26px;
        margin-left: 8px;
      }
    }
    &_pdf {
      display: flex;
      align-items: center;
      padding-bottom: 40px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        padding: 0 14px;
        height: 40px;
        background: #0f8c20;
        border-radius: 24px;
        &:hover {
          background: #00b900;
        }
      }
      .pdf-icon {
        width: 18px;
        height: 18px;
        background: url('../icon/CTK@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .pdf-text {
        margin-left: 8px;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 300;
        color: #ffffff;
      }
      .down-load {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../icon/folder-download@2x.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .xian-1 {
    height: 1px;
    background: #002C8E;
  }
  .xian-2 {
    height: 1px;
    background: #6F64CB;
  }
}
@media #{$mdWidth} {
  .banner {
    width: 1080px;
    margin: 0 auto;
    &_img {
      display: block;
      margin: 0 auto;
      width: 98%;
      height: 270px;
      // background-image: url('../image/weBanner.png');
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
    }
    img {
      width: 100px;
      height: 40px;
      // background-image: linear-gradient(#e66465, #9198e5);
    }
    &_text {
      font-size: 34px;
      font-weight: 600;
      color: #22292f;
      line-height: 40px;
      margin-top: 50px;
    }
    &_text-title {
      display: none;
    }
    &_dec {
      width: 522px;
      font-size: 18px;
      font-weight: 300;
      color: #7a7f82;
      line-height: 36px;
      margin-top: 20px;
    }
    &_link {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 42px;
      span {
        font-size: 20px;
        font-family: PTMono-Regular, PTMono !important;
        font-weight: 400;
        color: #22292f;
        line-height: 20px;
      }
      a {
        display: inline-block;
        background-image: url('../icon/huobi-logo.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 220px;
        height: 26px;
        margin-left: 8px;
      }
    }
    &_pdf {
      display: flex;
      align-items: center;
      padding-bottom: 40px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        padding: 0 14px;
        height: 40px;
        background: #0f8c20;
        border-radius: 24px;
        &:hover {
          background: #00b900;
        }
      }
      .pdf-icon {
        width: 18px;
        height: 18px;
        background: url('../icon/CTK@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .pdf-text {
        margin-left: 8px;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 300;
        color: #ffffff;
      }
      .down-load {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../icon/folder-download@2x.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .xian-1 {
    height: 1px;
    background: #002C8E;
  }
  .xian-2 {
    height: 1px;
    background: #6F64CB;
  }
}
@media #{$smWidth} {
  .banner {
    width: 100%;
    // padding: 0 15px;
    margin-top: -60px;
    &_img {
      display: block;
      margin: 0 auto;
      width: 98%;
      height: 270px;
      background-image: url('../image/weBanner_h5.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    &_text {
      display: block;
      font-size: 24px;
      font-weight: 600;
      color: #22292f;
      line-height: 40px;
      margin-top: 50px;
    }
    &_text-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      background: #d4e9e2;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        font-family: PTMono-Regular, PTMono !important;
        font-weight: 400;
        color: #22292f;
      }
      a {
        display: inline-block;
        background-image: url('../icon/huobi-logo.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100px;
        height: 12px;
        margin-left: 4px;
      }
    }
    img {
      width: 100px;
      height: 40px;
      // background-image: linear-gradient(#e66465, #9198e5);
    }
    &_dec {
      display: block;
      width: auto;
      font-size: 14px;
      font-weight: 300;
      color: #7a7f82;
      line-height: 20px;
      margin-top: 20px;
    }
    &_link {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 42px;
      span {
        font-size: 20px;
        font-family: PTMono-Regular, PTMono !important;
        font-weight: 400;
        color: #22292f;
        line-height: 20px;
      }
      a {
        display: inline-block;
        background-image: url('../icon/huobi-logo.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 220px;
        height: 26px;
        margin-left: 8px;
      }
      @media #{$xsWidth} {
        display: none;
      }
      @media #{$smWidth} {
        display: none;
      }
    }
    &_pdf {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        padding: 0 14px;
        height: 40px;
        background: #0f8c20;
        border-radius: 24px;
        &:hover {
          background: #00b900;
        }
      }
      .pdf-icon {
        width: 18px;
        height: 18px;
        background: url('../icon/CTK@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .pdf-text {
        margin-left: 8px;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 300;
        color: #ffffff;
      }
      .down-load {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../icon/folder-download@2x.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .xian-1 {
    height: 1px;
    background: #002C8E;
  }
  .xian-2 {
    height: 1px;
    background: #6F64CB;
  }
}
@media #{$xsWidth} {
  .banner {
    width: 100%;
    // padding: 0 15px;
    &_img {
      display: block;
      // display: block;
      margin: 0 auto;
      width: 99%;
      height: 270px;
      background-image: url('../image/weBanner_h5.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    &_text {
      display: block;
      font-size: 24px;
      font-weight: 600;
      color: #22292f;
      line-height: 42px;
    }
    img {
      width: 100px;
      height: 40px;
    }
    &_text-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 20px;
      background: #d4e9e2;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        font-family: PTMono-Regular, PTMono !important;
        font-weight: 400;
        color: #22292f;
      }
      a {
        display: inline-block;
        background-image: url('../icon/huobi-logo.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100px;
        height: 12px;
        margin-left: 4px;
      }
    }
    &_dec {
      display: none;
      width: auto;
      font-size: 14px;
      font-weight: 300;
      color: #7a7f82;
      line-height: 24px;
      margin-top: 20px;
    }
    &_link {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 42px;
      span {
        font-size: 20px;
        font-family: PTMono-Regular, PTMono !important;
        font-weight: 400;
        color: #22292f;
        line-height: 20px;
      }
      a {
        display: inline-block;
        background-image: url('../icon/huobi-logo.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 220px;
        height: 26px;
        margin-left: 8px;
      }
    }
    &_pdf {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        padding: 0 14px;
        height: 40px;
        background: #0f8c20;
        border-radius: 24px;
        &:hover {
          background: #00b900;
        }
      }
      .pdf-icon {
        width: 18px;
        height: 18px;
        background: url('../icon/CTK@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .pdf-text {
        margin-left: 8px;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 300;
        color: #ffffff;
      }
      .down-load {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../icon/folder-download@2x.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .xian-1 {
    height: 1px;
    background: #002C8E;
  }
  .xian-2 {
    height: 1px;
    background: #6F64CB;
  }
}
