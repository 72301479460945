@media #{$smWidth} { // 移动端适配
  .banners-index {
    width: 165px;
  }
}
@media #{$desktop} { // pc端
  .banners-index {
    width: 350px;
  }
}
@media #{$mdWidth} {
  .banners-index {
    width: 165px;
  }
}
@media #{$xsWidth} {
  .banners-index {
    width: 165px;
  }

}

@media #{$smWidth} { // 移动端适配
  .ewms {
    width: 200px;
    height: 200px;
    background: #FFFFFF;
    box-shadow: -8px 0px 20px 0px rgba(0, 26, 86, 0.2);
    border-radius: 12px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .gross {
    padding: 35px 20px;
    color: rgba(255, 225, 234, 0.996);
    font-size: 14px;
  }
  .jkc {
    width: 100%;
    .jckindex {
      width: 90%;
      margin: 0 auto;
      height: 30px;
      background: rgba(149, 136, 255, 0.4);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      position: relative;
      .what {
        color: #E1EAFE;
        font-size: 14px;
        padding-left: 20px;
        line-height: 30px;
      }
      .dw {
        position: absolute;
        right: 14px;
        top: -14px;
        img {
          width: 90px;
          height: 40px;
        }
      }
    }
    .solve {
      width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      // height: 180px;
      // padding-top: 30px;
      background: rgba(149, 136, 255, 0.1);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      color: #E1EAFE;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 20px;
    }
    .draps-activity {
      width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      height: 400px;
      background: url(../image/kt.png) no-repeat;
      background-size: 100% 100%;
      .idsx {
        padding-top: 50px;
      }
      .count-down {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #E1EAFE;
        text-align: center;
        padding-top: 59px;
      }
      .counter {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #E1EAFE;
        text-align: center;
        margin-top: 80px;
      }
    }
    .x-1 {
      width: 90%;
      margin: 0 auto;
      margin-top: 18px;
      height: 1px;
      background: #002C8E;
    }
    .x-1 {
      width: 90%;
      margin: 0 auto;
      height: 1px;
      background: #6F64CB;
    }
    .token {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      margin-top: 15px;
      color: #E1EAFE;
      text-align: center;
    }
    .contract {
      width: 84%;
      margin: 0 auto;
      margin-top: 16px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #C1D4FF;
      display: flex;
      flex-direction: column;
    }
  }
}
@media #{$desktop} { // pc端
  .ewms {
    width: 200px;
    height: 200px;
    background: #FFFFFF;
    box-shadow: -8px 0px 20px 0px rgba(0, 26, 86, 0.2);
    border-radius: 24px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .gross {
    width:1080px;
    margin: 0 auto;
    color: rgba(255, 225, 234, 0.996);
    font-size: 18px;
  }
  .jkc {
    width: 100%;
    .jckindex {
      width: 1080px;
      margin: 0 auto;
      height: 60px;
      background: rgba(149, 136, 255, 0.4);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      position: relative;
      .what {
        color: #E1EAFE;
        font-size: 18px;
        padding-left: 20px;
        line-height: 60px;
      }
      .dw {
        position: absolute;
        right: 14px;
        top: -20px;
        img {
          width: 150px;
          height: 60px;
        }
      }
    }
    .solve {
      width: 1080px;
      margin: 0 auto;
      margin-top: 30px;
      height: 100px;
      background: rgba(149, 136, 255, 0.1);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      color: #E1EAFE;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
    }
    .draps-activity {
      width: 1080px;
      margin: 0 auto;
      margin-top: 30px;
      height: 1000px;
      background: url(../image/kt.png) no-repeat;
      background-size: 100% 100%;
      .idsx {
        padding-top: 161px;
      }
      .count-down {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #E1EAFE;
        text-align: center;
        padding-top: 320px;
      }
      .counter {
        font-size: 48px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #E1EAFE;
        text-align: center;
        margin-top: 280px;
      }
    }
    .x-1 {
      width: 90%;
      margin: 0 auto;
      margin-top: 18px;
      height: 1px;
      background: #002C8E;
    }
    .x-1 {
      width: 1080px;
      margin: 0 auto;
      height: 1px;
      background: #6F64CB;
    }
    .token {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      margin-top: 15px;
      color: #E1EAFE;
      text-align: center;
    }
    .contract {
      width: 1080px;
      margin: 0 auto;
      margin-top: 16px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #C1D4FF;
      display: flex;
      flex-direction: column;
    }
  }
}
@media #{$mdWidth} {
  .ewms {
    width: 200px;
    height: 200px;
    background: #FFFFFF;
    box-shadow: -8px 0px 20px 0px rgba(0, 26, 86, 0.2);
    border-radius: 24px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .gross {
    padding: 35px 20px;
    color: rgba(255, 225, 234, 0.996);
    font-size: 14px;
  }
  .jkc {
    width: 100%;
    .jckindex {
      width: 90%;
      margin: 0 auto;
      height: 30px;
      background: rgba(149, 136, 255, 0.4);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      position: relative;
      .what {
        color: #E1EAFE;
        font-size: 14px;
        padding-left: 20px;
        line-height: 30px;
      }
      .dw {
        position: absolute;
        right: 14px;
        top: -14px;
        img {
          width: 90px;
          height: 40px;
        }
      }
    }
    .solve {
      width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      height: 100px;
      background: rgba(149, 136, 255, 0.1);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      color: #E1EAFE;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
    }
    .draps-activity {
      width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      height: 400px;
      background: url(../image/kthd.png) no-repeat;
      background-size: 100% 100%;
      .count-down {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #E1EAFE;
        text-align: center;
        padding-top: 120px;
      }
      .counter {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #E1EAFE;
        text-align: center;
        margin-top: 80px;
      }
    }
    .x-1 {
      width: 90%;
      margin: 0 auto;
      margin-top: 18px;
      height: 1px;
      background: #002C8E;
    }
    .x-1 {
      width: 90%;
      margin: 0 auto;
      height: 1px;
      background: #6F64CB;
    }
    .token {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      margin-top: 15px;
      color: #E1EAFE;
      text-align: center;
    }
    .contract {
      width: 84%;
      margin: 0 auto;
      margin-top: 16px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #C1D4FF;
      display: flex;
      flex-direction: column;
    }
  }
}
@media #{$xsWidth} {
  .ewms {
    width: 200px;
    height: 200px;
    background: #FFFFFF;
    box-shadow: -8px 0px 20px 0px rgba(0, 26, 86, 0.2);
    border-radius: 24px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .gross {
    padding: 35px 20px;
    color: rgba(255, 225, 234, 0.996);
    font-size: 14px;
  }
  .jkc {
    width: 100%;
    .jckindex {
      width: 90%;
      margin: 0 auto;
      height: 30px;
      background: rgba(149, 136, 255, 0.4);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      position: relative;
      .what {
        color: #E1EAFE;
        font-size: 14px;
        padding-left: 20px;
        line-height: 30px;
      }
      .dw {
        position: absolute;
        right: 14px;
        top: -14px;
        img {
          width: 90px;
          height: 40px;
        }
      }
    }
    .solve {
      width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      height: 100px;
      background: rgba(149, 136, 255, 0.1);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      color: #E1EAFE;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
    }
    .draps-activity {
      width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      height: 400px;
      background: url(../image/kthd.png) no-repeat;
      background-size: 100% 100%;
      .count-down {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #E1EAFE;
        text-align: center;
        padding-top: 120px;
      }
      .counter {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #E1EAFE;
        text-align: center;
        margin-top: 80px;
      }
    }
    .x-1 {
      width: 90%;
      margin: 0 auto;
      margin-top: 18px;
      height: 1px;
      background: #002C8E;
    }
    .x-1 {
      width: 90%;
      margin: 0 auto;
      height: 1px;
      background: #6F64CB;
    }
    .token {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      margin-top: 15px;
      color: #E1EAFE;
      text-align: center;
    }
    .contract {
      width: 84%;
      margin: 0 auto;
      margin-top: 16px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #C1D4FF;
      display: flex;
      flex-direction: column;
    }
  }
}