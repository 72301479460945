* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;

  &.scroll-behavior-off {
    scroll-behavior: auto;
  }
}

body {
  color: var(--color);
  min-width: 320px;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: scrollbar;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  background-color: var(--bg);

  /* IE10+ CSS styles go here */
  @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: 100vh;
  }
}

#root {
  height: 100%;
  overflow-y: auto;
}

a {
  color: var(--color);
  text-decoration: none;
  transition: var(--transitionDefault);

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

::selection {
  color: var(--bg);
  background-color: var(--yellow);
}

img {
  border: 0;
  display: block;
  position: relative;
}

li {
  list-style: none;
  position: relative;
}

svg {
  display: block;
  fill: var(--color);
  position: relative;
  transition: var(--transitionDefault);
}

table {
  border: 0;
  border-collapse: collapse;
}

hr {
  height: 1px;
  border: 0;
  background-color: #fff;
  opacity: 0.1;
}

.align-center {
  text-align: center;
}

.button-row {
  width: 100%;
  display: flex;
  gap: 12px;
}

/*
-----------------------------------------------------------------------------*/
.notsel {
  user-select: none;
}

.h1 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 400;

  @media #{$mdWidth} {
    font-size: 36px;
    line-height: 48px;
  }

  @media #{$smWidth} {
    font-size: 24px;
    line-height: 36px;
  }
}

.h2 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 400;
}

.h3 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}

.status {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #22292f;
  margin-top: 8px !important;
}

.h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

main,
header,
footer,
section,
article,
aside,
time,
nav {
  display: block;
  position: relative;
}

.wrapper,
.main {
  height: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* hidden
-----------------------------------------------------------------------------*/
.hide {
  display: none !important;
}

.visuallyhidden {
  position: absolute !important;

  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;

  clip: rect(0 0 0 0) !important;
  overflow: hidden !important;
}

a[href^='tel'],
.white-space-nowrap {
  white-space: nowrap;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-40 {
  opacity: 0.4;
}

.fz-14 {
  font-size: 14px;
}

.fz-20 {
  font-size: 20px;
}

.color-gray {
  color: #808080;
}

.value {
  font-size: 18px;
  font-family: HelveticaNeue-Bold, HelveticaNeue;
  font-weight: bold;
  color: #22292f;
}

.withdraw-title {
  font-size: 12px;
  font-family: HelveticaNeue;
  color: #7a7f82;
}

.gold-color {
  color: var(--yellow);
}

.language {
  border-radius: 5px;
  font-size: 14px;
  font-family: HelveticaNeue;
  color: #22292f;
  padding: 8px;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  position: relative;

  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .language-items {
    display: none;
    flex-direction: column;
    background: #f2f0eb;
    box-shadow: 0px 0px 6px 0px rgba(122, 127, 130, 0.2);
    border-radius: 5px 5px 0px 0px;
    padding: 12px 10px;
    right: 50%;
    position: absolute;
    bottom: 80%;
    transform: translate(50%);
    p {
      color: #22292f;
      width: 90px;
      padding: 5px 0;
      text-align: center;
      &:hover {
        background-color: rgba(212, 233, 226, 1);
        border-radius: 5px;
      }
    }
  }

  &:hover {
    display: flex;
    background: #f2f0eb;
    .language-items {
      display: flex;
    }
  }
}

.satellite {
  position: fixed;
  width: 252px;
  bottom: 0;
  right: 0;
}
@media #{$smWidth} {
  .satellite {
    position: absolute;
    width: 252px;
    bottom: 0;
    right: 50%;
    transform: translate(50%);
  }
  .language {
    background: transparent;
    border-radius: 5px;
    font-size: 14px;
    font-family: HelveticaNeue;
    color: #22292f;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    position: relative;
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .language-items {
      display: none;
      flex-direction: column;
      background: #f2f0eb;
      box-shadow: 0px 0px 6px 0px rgba(122, 127, 130, 0.2);
      border-radius: 5px 5px 0px 0px;
      bottom: 40px;
      position: absolute;
      left: 0;
      padding: 12px 10px;
      // right: 50%;
      // transform: translateX(50%);
      p {
        color: #22292f;
        width: 90px;
        padding: 5px 0;
        text-align: center;
        &:hover {
          background-color: rgba(212, 233, 226, 1);
          border-radius: 5px;
        }
      }
    }

    &:hover {
      display: flex;
      background: #f2f0eb;
      .language-items {
        display: flex;
      }
    }
  }
}
.right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
