.pools_banner {
  width: 100%;
}

@media #{$desktop} {
  .pools_banner {
    width: 1080px;
    text-align: center;
    margin: 0 auto;
    height: 78px;
    // background: rgba(149, 136, 255, 0.4);
    margin-top: 50px;
    
background: rgba(81, 193, 194, 0.4);
    box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    &_img {
      display: none;
      margin: 0 auto;
      width: 100%;
      height: 378px;
      background-image: url('../image/pools-banner@2x.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    &_text {
      margin-bottom: 0;
      font-size: 56px;
      font-weight: 600;
      color: #22292f;
      line-height: 78px;
      // margin-top: 100px;
    }
    &_dec {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 28px;
      font-size: 24px;
      font-weight: 700;
      color: #16d3e0;
      line-height: 36px;
      // margin-top: 16px;
      margin-bottom: 0;
      display: flex;
      justify-content: space-around;
      width: 70%;
      a {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../image/file-copy-line@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .cv {
        width: 35px;
        height: 35px;
      }
      .xx {
        width: 2px;
        height: 50px;
        background: #8279CC;
      }
    }
    &_withdraw_tip {
      display: inline-block;
      padding: 6px 8px;
      margin: 10px auto 0;
      font-size: 12px;
      color: #ff6400;
      background: #fff3f0;
      border-radius: 5px;
    }
    &_btn {
      display: inline-block;
      width: 128px;
      height: 48px;
      font-size: 16px;
      color: #22292f;
      font-weight: 600;
      line-height: 48px;
      border-radius: 24px;
      background: #f2f0eb;
      &:hover {
        color: #fff;
      }
      &:last-child {
        margin-left: 24px;
        &:hover {
          color: #1890ff;
        }
      }
    }
    &_btn_active {
      color: #fff;
      background: #1890ff;
      box-shadow: 0px 4px 20px 0px rgba(15, 140, 32, 0.3);
    }

    &_btn_disable {
      color: #fff;
      background: #999;
      box-shadow: 0px 4px 20px 0px rgba(#999, 0.3);
    }
  }
  .gross {
    margin-top: 30px;
  }
}
@media #{$mdWidth} {
  .pools_banner {
    width: 90%;
    text-align: center;
    margin: 0 auto;
    height: 38px;
    background: rgba(149, 136, 255, 0.4);
    box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 44px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    &_img {
      display: none;
      margin: 0 auto;
      width: 98%;
      height: 378px;
      background-image: url('../image/pools-banner@2x.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    &_text {
      margin-bottom: 0;
      font-size: 56px;
      font-weight: 600;
      color: #22292f;
      line-height: 78px;
      margin-top: 100px;
    }
    &_dec {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 28px;
      font-size: 24px;
      font-weight: 700;
      color: #16d3e0;
      line-height: 36px;
      margin-top: 16px;
      margin-bottom: 0;
      span {
        width: 70%; 
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis
      }
      a {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../image/file-copy-line@2x.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    &_withdraw_tip {
      display: inline-block;
      padding: 6px 8px;
      margin: 10px auto 0;
      font-size: 12px;
      color: #ff6400;
      background: #fff3f0;
      border-radius: 5px;
    }
    &_btn {
      display: inline-block;
      width: 128px;
      height: 48px;
      font-size: 16px;
      color: #22292f;
      font-weight: 600;
      line-height: 48px;
      border-radius: 24px;
      background: #f2f0eb;
      &:hover {
        color: #fff;
      }
      &:last-child {
        margin-left: 24px;
        &:hover {
          color: #1890ff;
        }
      }
    }
    &_btn_active {
      color: #fff;
      background: #1890ff;
      box-shadow: 0px 4px 20px 0px rgba(15, 140, 32, 0.3);
    }

    &_btn_disable {
      color: #fff;
      background: #999;
      box-shadow: 0px 4px 20px 0px rgba(#999, 0.3);
    }
  }
}
@media #{$smWidth} {
  .pools_banner {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 38px;
    // background: rgba(149, 136, 255, 0.4);
    // box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 20px;
    // height: 270px;
    // background-image: url('../image/pools-banner@2x_h5.png');
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    &_img {
      display: none;
      margin: 0 auto;
      // width: 98%;
      height: 270px;
      background-image: url('../image/pools-banner@2x_h5.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    &_text {
      margin-bottom: 0;
      // width: 100%;
      font-size: 30px;
      font-weight: 600;
      color: #22292f;
      line-height: 42px;
    }
    &_dec {
      line-height: 24px;
      word-break: break-word;
      // margin-top: 20px;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
      font-family: DIN Condensed;
      font-weight: bold;
      color: #14144E;
      display: flex;
      // align-items: center;
      // justify-content: space-around;
      width: 100%;
      height: 40px;
      // padding-left: 100px;
      // background: rgba(149, 136, 255, 0.4);
      // box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      // border-radius: 22px;
      span {
        width: 80%; 
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis
      }
      a {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url('../image/file-copy-line@2x.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .cv {
      width: 22px;
      height: 22px;
      margin-left: 10px;
    }
    .xx {
      width: 1px;
      height: 25px;
      background: #8279CC;
    }
    &_withdraw_tip {
      display: inline-block;
      padding: 6px 8px;
      margin: 10px auto 0;
      max-width: 320px;
      font-size: 12px;
      color: #ff6400;
      background: #fff3f0;
      border-radius: 5px;
    }
    &_btn {
      display: inline-block;
      width: 128px;
      height: 48px;
      font-size: 16px;
      color: #22292f;
      font-weight: 600;
      line-height: 48px;
      border-radius: 24px;
      background: #f2f0eb;
      &:hover {
        color: #fff;
      }
      &:last-child {
        margin-left: 24px;
        &:hover {
          color: #1890ff;
        }
      }
    }
    &_btn_active {
      color: #fff;
      background: #1890ff;
      box-shadow: 0px 4px 20px 0px rgba(15, 140, 32, 0.3);
    }

    &_btn_disable {
      color: #fff;
      background: #999;
      box-shadow: 0px 4px 20px 0px rgba(#999, 0.3);
    }
  }
}
@media #{$xsWidth} {
  .pools_banner {
    width: 90%;
    text-align: center;
    margin: 0 auto;
    height: 38px;
    background: rgba(149, 136, 255, 0.4);
    box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    // height: 270px;
    // background-image: url('../image/pools-banner@2x_h5.png');
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    &_img {
      display: none;
      margin: 0 auto;
      width: 99%;
      height: 270px;
      background-image: url('../image/pools-banner@2x_h5.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    &_text {
      margin-bottom: 0;
      font-size: 30px;
      font-weight: 600;
      color: #22292f;
      line-height: 42px;
    }
    &_dec {
      font-size: 12px;
      font-weight: 700;
      color: #16d3e0;
      line-height: 36px;
      word-break: break-word;
      margin-bottom: 0;
      span {
        width: 70%; 
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis
      }
      a {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url('../image/file-copy-line@2x.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    &_withdraw_tip {
      display: inline-block;
      padding: 6px 8px;
      margin: 10px auto 0;
      max-width: 320px;
      font-size: 12px;
      color: #ff6400;
      background: #fff3f0;
      border-radius: 5px;
    }
    &_btn {
      display: inline-block;
      width: 128px;
      height: 48px;
      font-size: 16px;
      color: #22292f;
      font-weight: 600;
      line-height: 48px;
      border-radius: 24px;
      background: #f2f0eb;
      &:hover {
        color: #fff;
      }
      &:last-child {
        margin-left: 24px;
        &:hover {
          color: #1890ff;
        }
      }
    }
    &_btn_active {
      color: #fff;
      background: #1890ff;
      box-shadow: 0px 4px 20px 0px rgba(15, 140, 32, 0.3);
    }

    &_btn_disable {
      color: #fff;
      background: #999;
      box-shadow: 0px 4px 20px 0px rgba(#999, 0.3);
    }
  }
}
