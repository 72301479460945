.pools_card {
  width: 100%;
  // background: #fff;
}
@media #{$desktop} {
  .pools_card {
    padding: 40px 0 40px;
    margin-top: 30px;
    &_content {
      padding: 30px 50px 40px;
      margin: 0 auto;
      width: 640px;
      height: 294px;
      background: #fff;
      border-radius: 10px;
    }
    &_content_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #1890ff;
        &:last-child {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
    &_val {
      margin-top: 10px;
      font-size: 24px;
      color: #1890ff;
      line-height: 24px;
    }
    &_start {
      margin: 34px 0 12px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
    &_progress__bar {
      position: relative;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      span {
        position: absolute;
        top: 0;
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url('../image/ant@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      p {
        width: 540px;
        height: 14px;
        background: #abcdef;
        border-radius: 7px;
        a {
          display: inline-block;
          height: 100%;
          background: #1890ff;
          border-radius: 7px;
          vertical-align: super;
        }
      }
    }
    &_schedule {
      span {
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        margin-top: 8px;
      }
    }
  }
}
@media #{$mdWidth} {
  .pools_card {
    padding: 40px 0 40px;
    &_content {
      padding: 30px 50px 40px;
      margin: 0 auto;
      width: 640px;
      height: 294px;
      background: #fff;
      border-radius: 10px;
    }
    &_content_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #1890ff;
        &:last-child {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
    &_val {
      margin-top: 10px;
      font-size: 24px;
      color: #1890ff;
      line-height: 24px;
    }
    &_start {
      margin: 34px 0 12px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
    &_progress__bar {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url('../image/ant@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      p {
        width: 540px;
        height: 14px;
        background: #abcdef;
        border-radius: 7px;
        a {
          display: inline-block;
          width: 10px;
          height: 100%;
          background: #1890ff;
          border-radius: 7px;
          vertical-align: super;
        }
      }
    }
    &_schedule {
      span {
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        margin-top: 8px;
      }
    }
  }
}
@media #{$smWidth} {
  .pools_card {
    padding: 30px 0 20px;
    &_content {
      padding: 30px 18px 20px;
      // box-shadow: 2px 3px 10px 0px #ebebeb;
      box-shadow: 0px 0px 20px 0px rgba(0, 51, 44, 0.2);
      margin: 0 auto;
      width: 340px;
      height: 100%;
      background: #fff;
      border-radius: 10px;
    }
    &_content_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #19DC9F;
        &:last-child {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
    &_val {
      margin-top: 30px;
      font-size: 20px;
      // color: #1890ff;
      font-weight: 500;
      color: #19DC9F;
      line-height: 24px;
    }
    &_start {
      margin: 34px 0 12px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
    &_progress__bar {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url('../image/ant@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      p {
        width: 280px;
        height: 14px;
        background: #ffffff;
        border-radius: 7px;
        border: 1px solid #19DC9F;
        a {
          display: inline-block;
          width: 10px;
          height: 100%;
          background: #19DC9F;
          border-radius: 7px;
          vertical-align: super;
        }
      }
    }
    &_schedule {
      span {
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        margin-top: 8px;
      }
    }
  }
}
@media #{$xsWidth} {
  .pools_card {
    padding: 20px 0 20px;
    &_content {
      padding: 30px 18px 40px;
      margin: 0 auto;
      width: 320px;
      height: 294px;
      background: #1890ff;
      border-radius: 10px;
    }
    &_content_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #1890ff;
        &:last-child {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
    &_val {
      margin-top: 10px;
      font-size: 24px;
      color: #1890ff;
      line-height: 24px;
    }
    &_start {
      margin: 34px 0 12px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
    &_progress__bar {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url('../image/ant@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      p {
        width: 280px;
        height: 14px;
        background: #abcdef;
        border-radius: 7px;
        a {
          display: inline-block;
          width: 10px;
          height: 100%;
          background: #1890ff;
          border-radius: 7px;
          vertical-align: super;
        }
      }
    }
    &_schedule {
      span {
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        margin-top: 8px;
      }
    }
  }
}
