.head-page {

    margin-bottom: 72px;
    text-align: center;
    
    &.head-page--margin-top {
        margin: 116px 0 72px;
    }
    .menu {
		.menu__link {
			margin-top: 120px;
			width: 332px;
			height: 48px;
			border: 1px solid rgba(122, 127, 130, 0.2);
			border-radius: 24px;
			line-height: 48px;
			&.is-current {
				position: relative;
				background-color: #0F8C20;
				color: #FFFFFF;
				&::after {
					position: absolute;
					top: -40px;
					left: 50%;
					margin-left: -20px;
					content: '';
					width: 40px;
					height: 40px;
					display: block;
					background-image: url('../icon/ant.png');
					background-size: 100% 100%;
				}
			}
		}
	}
	@media #{$smWidth} {
		margin: 20px 0;
  
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 22px 0;

		img{
			width: 40px;
		}
	}

	&__intro {

		font-size: 20px;
		color: rgba(255,255,255,.7);
		margin: 22px 0;

		@media #{$smWidth} {

			font-size: 16px;
			margin: 16px 0;

		}

	}

	&__btn {

		display: flex;
		justify-content: center;

	}

	&__audited {
		margin-top: 8px;
		height: 64px;
		line-height: 64px;
		border-bottom: 1px solid #fff;
		&:hover{
			color: var(--yellow);
			border-bottom: 1px solid var(--yellow);
		}
	}

}

.arrow-back {
    margin: 41px 0;
    
    a {
        display: inline-flex;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
    }

    svg {
        margin-right: 16px;
    }
}