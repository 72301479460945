.pools-type {
  width: 100%;
  // min-height: 800px;
  p {
    margin-bottom: 0;
  }
}
.pools-type_card_box_border {
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
    border: 1px solid #1890ff;
  }
}
@media #{$desktop} {
  .pools-type {
    &_big_title {
      display: block;
      margin: 20px 0 40px;
      height: 50px;
      font-size: 42px;
      color: #1890ff !important;
      line-height: 50px;
      text-align: center;
    }
    &-top {
      width: 100%;
      min-height: 600px;
      // background: #f2f0eb;
      background: url(../image/index/bg.png) no-repeat;
      background-size: 100% 100%;
    }
    &-bottom {
      padding: 120px 0 180px;
      width: 100%;
      text-align: center;
      background: #fff;
      h2 {
        margin-bottom: 0;
        height: 44px;
        font-size: 44px;
        font-weight: 600;
        color: #22292f;
        line-height: 44px;
      }
    }
    &-bottom_content {
      padding: 100px 0 0;
      width: 1080px;
      margin: 0 auto;
      .pools-type-bottom_content_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          display: inline-block;
          width: 302px;
          height: 100px;
          padding: 30px 10px;
          background: #ffffff;
          border-radius: 30px;
          border: 1px solid #e1dfd9;
          &:hover {
            cursor: pointer;
            border: 1px solid #1890ff;
          }
        }
        &:nth-child(2) {
          margin-top: 80px;
        }
        &:nth-child(3) {
          margin-top: 80px;
        }
      }
    }
    &_content {
      margin: 0 auto;
      padding-bottom: 50px;
      width: 1080px;
    }
    &_tab {
      padding-top: 60px;
      display: flex;
      width: 100%;
      border-bottom: 2px solid #ebe9e3;
      .flashPool_png {
        margin-right: 4px;
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: text-top;
      }
      h2 {
        position: relative;
        margin-bottom: 0;
        margin-right: 50px;
        height: 60px;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        line-height: 60px;
        &:hover {
          cursor: pointer;
        }
        &.tab_active {
          color: #22292f;
          &:after {
            position: absolute;
            content: '';
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 4px;
            background: #1890ff;
          }
        }
      }
      .new_flag {
        &:before {
          position: absolute;
          content: 'NEW';
          top: 8px;
          right: -30px;
          padding: 0 4px;
          height: 16px;
          line-height: 16px;
          color: #fff;
          font-size: 12px;
          transform: scale(0.8);
          border-radius: 3px;
          background: #f43900;
        }
      }
    }
    &_noData {
      padding-top: 120px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 370px;
        height: 200px;
      }
      p {
        margin-top: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #7a7f82;
      }
    }
    &_card {
      margin-top: 30px;
      display: flex;
      .pools-type_card_box {
        box-sizing: border-box;
        display: inline-block;
        // margin-top: 20px;
        padding: 20px 30px 40px 30px;
        width: 332px;
        height: 100%;
        background: #fafffc;
        border-radius: 20px;
        &:nth-child(3n-1) {
          margin: 0 40px;
        }
      }
      .pools-type_private {
        background: #fffbfa;
      }
      .pools-type_flashPool {
        background: url('../icon/shandian@2x.png') no-repeat;
        background-position: right bottom;
        background-size: 110px 150px;
        background-color: #fffaf2;
      }
    }
    &_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        display: inline-block;
        // width: 50px;
        height: 50px;
        margin-right: 6px;
      }
      .pools-type_card_title {
        display: inline-block;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: bold;
        color: #22292f;
        line-height: 20px;
      }
      .pools-type_card_title_right {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .pools-type_time {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        span {
          font-style: normal;
          display: inline-block;
          padding: 0 4px;
          height: 20px;
          text-align: center;
          background: #f2f0eb;
          border-radius: 3px;
          font-size: 12px;
          font-weight: 600;
          color: #22292f;
          line-height: 20px;
        }
      }
      span {
        display: inline-block;
        padding: 0 4px;
        height: 18px;
        font-size: 12px;
        font-weight: 400;
        color: #22292f;
        line-height: 18px;
        transform: scale(0.9);
        border-radius: 3px;
      }
      .pools-type_over_status {
        color: #42526e;
        background: #dfe1e6;
      }
      .pools-type_progress_status {
        color: #1890ff;
        background: #d4e9e2;
      }
      .pools-type_coming_status {
        color: #22292f;
        background: #fff0b3;
      }
      .pools-type_card_ratio {
        margin-top: 14px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: bold;
        color: #22292f;
        line-height: 14px;
        i {
          margin-top: 10px;
          display: block;
          font-style: normal;
          font-size: 12px;
          color: #22292f;
          line-height: 12px;
        }
      }
      .pools-type_card_access {
        margin-top: 20px;
        line-height: 24px;
        // display: flex;
        // align-items: center;
      }
      .crown,
      .tips,
      .crown-highlight {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
      .crown {
        margin-right: 2px;
        background: url('../icon/home_N02_night@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .crown-highlight {
        margin-right: 2px;
        background: url('../icon/home_N01_night@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .tips {
        position: relative;
        margin-left: 2px;
        background: url('../icon/06\ icon／minor／info@2x.png') no-repeat;
        background-size: 100% 100%;
        &:hover {
          cursor: pointer;
        }
      }
      .tips_content {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 10px;
        margin-top: 0 !important;
        height: 24px;
        font-style: normal;
        font-size: 12px;
        color: #22292f;
        line-height: 24px !important;
        background: #e1dfd9;
        border-radius: 3px;
        white-space: nowrap;
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-top: 7px solid #e1dfd9;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
        }
      }
    }
    &_percentage {
      a {
        margin-top: 10px;
        position: relative;
        display: block;
        width: 100%;
        height: 8px;
        background: #f2f0eb;
        border-radius: 5px;
        .pools-type_progress_bar {
          position: absolute;
          left: 0;
          top: 0;
          height: 8px;
          background: #1890ff;
          border-radius: 5px;
        }
      }
      p {
        margin-top: 4px;
        margin-bottom: 0;
        font-size: 12px;
        color: #22292f;
        line-height: 12px;
      }
    }
    &_enter {
      margin-top: 24px;
      display: inline-block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      background: #1890ff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      text-align: center;
      &:hover {
        color: #fff;
        background: #00b900;
      }
    }
    &_disable_enter {
      color: #fff;
      background: #b6b9bc;
      &:hover {
        color: #fff;
        background: #b6b9bc;
      }
    }
  }
}
@media #{$mdWidth} {
  .pools-type {
    min-width: 1080px;
    &_big_title {
      display: block;
      margin: 20px 0 40px;
      height: 50px;
      font-size: 42px;
      color: #1890ff !important;
      line-height: 50px;
      text-align: center;
    }
    &-top {
      width: 100%;
      min-height: 600px;
      background: #f2f0eb;
    }
    &-bottom {
      padding: 90px 0 100px;
      width: 100%;
      text-align: center;
      background: #fff;
      h2 {
        margin-bottom: 0;
        height: 44px;
        font-size: 44px;
        font-weight: 600;
        color: #22292f;
        line-height: 44px;
      }
    }
    &-bottom_content {
      padding: 100px 0 0;
      width: 1080px;
      margin: 0 auto;
      .pools-type-bottom_content_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          display: inline-block;
          width: 302px;
          height: 100px;
          padding: 30px 10px;
          background: #ffffff;
          border-radius: 30px;
          border: 1px solid #e1dfd9;
          &:hover {
            cursor: pointer;
            border: 1px solid #1890ff;
          }
        }
        &:nth-child(2) {
          margin-top: 80px;
        }
        &:nth-child(3) {
          margin-top: 80px;
        }
      }
    }
    &_content {
      margin: 0 auto;
      padding-bottom: 50px;
      width: 1080px;
    }
    &_tab {
      padding-top: 60px;
      display: flex;
      width: 100%;
      border-bottom: 2px solid #ebe9e3;
      .flashPool_png {
        margin-right: 4px;
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: text-top;
      }
      h2 {
        position: relative;
        margin-bottom: 0;
        margin-right: 50px;
        height: 60px;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        line-height: 60px;
        &:hover {
          cursor: pointer;
        }
        &.tab_active {
          color: #22292f;
          &:after {
            position: absolute;
            content: '';
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 4px;
            background: #1890ff;
          }
        }
      }
      .new_flag {
        &:before {
          position: absolute;
          content: 'NEW';
          top: 8px;
          right: -30px;
          padding: 0 4px;
          height: 16px;
          line-height: 16px;
          color: #fff;
          font-size: 12px;
          transform: scale(0.8);
          border-radius: 3px;
          background: #f43900;
        }
      }
    }
    &_noData {
      padding-top: 120px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 370px;
        height: 200px;
      }
      p {
        margin-top: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #7a7f82;
      }
    }
    &_card {
      margin-top: 30px;
      display: flex;
      .pools-type_card_box {
        box-sizing: border-box;
        display: inline-block;
        // margin-top: 20px;
        padding: 30px 30px 40px 30px;
        width: 332px;
        height: 368px;
        background: #fff;
        border-radius: 20px;
        &:nth-child(3n-1) {
          margin: 0 40px;
        }
      }
      .pools-type_private {
        background: #fffbfa;
      }
      .pools-type_flashPool {
        background: url('../icon/shandian@2x.png') no-repeat;
        background-position: right bottom;
        background-size: 110px 150px;
        background-color: #fffaf2;
      }
    }
    &_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        display: inline-block;
        // width: 50px;
        // height: 50px;
        width: 100px;
        height: 40px;
        margin-right: 6px;
      }
      .pools-type_card_title {
        display: inline-block;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: bold;
        color: #22292f;
        line-height: 20px;
      }
      .pools-type_card_title_right {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .pools-type_time {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        span {
          font-style: normal;
          display: inline-block;
          padding: 0 4px;
          height: 20px;
          text-align: center;
          background: #f2f0eb;
          border-radius: 3px;
          font-size: 12px;
          font-weight: 600;
          color: #22292f;
          line-height: 20px;
        }
      }
      span {
        display: inline-block;
        padding: 0 4px;
        height: 18px;
        font-size: 12px;
        font-weight: 400;
        color: #22292f;
        line-height: 18px;
        transform: scale(0.9);
        border-radius: 3px;
      }
      .pools-type_over_status {
        color: #42526e;
        background: #dfe1e6;
      }
      .pools-type_progress_status {
        color: #1890ff;
        background: #d4e9e2;
      }
      .pools-type_coming_status {
        color: #22292f;
        background: #fff0b3;
      }
      .pools-type_card_ratio {
        margin-top: 14px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: bold;
        color: #22292f;
        line-height: 14px;
        i {
          margin-top: 10px;
          display: block;
          font-style: normal;
          font-size: 12px;
          color: #22292f;
          line-height: 12px;
        }
      }
      .pools-type_card_access {
        margin-top: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
      }
      .crown,
      .tips,
      .crown-highlight {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
      .crown {
        margin-right: 2px;
        background: url('../icon/home_N02_night@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .crown-highlight {
        margin-right: 2px;
        background: url('../icon/home_N01_night@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .tips {
        margin-left: 2px;
        background: url('../icon/06\ icon／minor／info@2x.png') no-repeat;
        background-size: 100% 100%;
        &:hover {
          cursor: pointer;
        }
      }
      .tips_content {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 10px;
        margin-top: 0 !important;
        height: 24px;
        font-style: normal;
        font-size: 12px;
        color: #22292f;
        line-height: 24px !important;
        background: #e1dfd9;
        border-radius: 3px;
        white-space: nowrap;
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-top: 7px solid #e1dfd9;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
        }
      }
    }
    &_percentage {
      a {
        margin-top: 10px;
        position: relative;
        display: block;
        width: 100%;
        height: 8px;
        background: #f2f0eb;
        border-radius: 5px;
        .pools-type_progress_bar {
          position: absolute;
          left: 0;
          top: 0;
          height: 8px;
          background: #1890ff;
          border-radius: 5px;
        }
      }
      p {
        margin-top: 4px;
        margin-bottom: 0;
        font-size: 12px;
        color: #22292f;
        line-height: 12px;
      }
    }
    &_enter {
      margin-top: 24px;
      display: inline-block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      background: #1890ff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      text-align: center;
      &:hover {
        color: #fff;
        background: #00b900;
      }
    }
    &_disable_enter {
      color: #fff;
      background: #b6b9bc;
      &:hover {
        color: #fff;
        background: #b6b9bc;
      }
    }
  }
}
@media #{$smWidth} {
  .pools-type {
    &_big_title {
      display: block;
      margin: 20px 0 40px;
      height: 50px;
      font-size: 42px;
      color: #1890ff !important;
      line-height: 50px;
      text-align: center;
    }
    &-top {
      width: 100%;
      // background: #F8F8FA;
      // width: 100%;
      // height: 100%;
      background: url(../image/index/bg.png) no-repeat;
      background-size: 100% 100%;
    }
    &-bottom {
      padding: 60px 0 50px;
      width: 100%;
      text-align: center;
      background: #fff;
      h2 {
        margin-bottom: 0;
        height: 44px;
        font-size: 44px;
        font-weight: 600;
        color: #22292f;
        line-height: 44px;
      }
    }
    &-bottom_content {
      padding: 60px 0 0;
      width: 100%;
      margin: 0 auto;
      .pools-type-bottom_content_box {
        img {
          display: inline-block;
          margin-bottom: 30px;
          width: 302px;
          height: 100px;
          padding: 30px 10px;
          background: #ffffff;
          border-radius: 30px;
          border: 1px solid #e1dfd9;
          &:hover {
            cursor: pointer;
            border: 1px solid #1890ff;
          }
        }
        &:nth-child(2) {
          margin-top: 0;
        }
        &:nth-child(3) {
          margin-top: 0;
        }
      }
    }
    &_content {
      margin: 0 auto;
      padding: 0 15px 0px;
      width: 100%;
    }
    &_tab {
      display: flex;
      width: 100%;
      border-bottom: 2px solid #ebe9e3;
      .flashPool_png {
        margin-right: 4px;
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: text-top;
      }
      h2 {
        position: relative;
        margin-bottom: 0;
        margin-right: 30px;
        height: 60px;
        font-size: 12px;
        font-weight: 500;
        color: #7a7f82;
        line-height: 60px;
        &:hover {
          cursor: pointer;
        }
        &.tab_active {
          color: #22292f;
          &:after {
            position: absolute;
            content: '';
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 4px;
            background: #1890ff;
          }
        }
      }
      .new_flag {
        &:before {
          position: absolute;
          content: 'NEW';
          top: 8px;
          right: -30px;
          padding: 0 4px;
          height: 16px;
          line-height: 16px;
          color: #fff;
          font-size: 12px;
          transform: scale(0.8);
          border-radius: 3px;
          background: #f43900;
        }
      }
    }
    &_noData {
      padding-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 270px;
        height: 150px;
      }
      p {
        margin-top: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #7a7f82;
      }
    }
    &_card {
      margin-top: 10px;
      .pools-type_card_box {
        box-sizing: border-box;
        display: block;
        margin: 20px auto 0;
        padding: 0px 30px 20px 30px;
        width: 332px;
        // height: 368px;
        // height: 280px;
        background: #fff;
        border-radius: 20px;
      }
      .pools-type_private {
        background: #fafbff;
      }
      .pools-type_flashPool {
        background: url('../icon/shandian@2x.png') no-repeat;
        background-position: right bottom;
        background-size: 110px 150px;
        background-color: #fffaf2;
      }
    }
    &_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        display: inline-block;
        // width: 50px;
        // height: 50px;
        width: 100px;
        height: 40px;
        margin-right: 6px;
      }
      .pools-type_card_title {
        display: inline-block;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: bold;
        color: #22292f;
        line-height: 20px;
      }
      .pools-type_card_title_right {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .pools-type_time {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        span {
          font-style: normal;
          display: inline-block;
          padding: 0 4px;
          height: 20px;
          text-align: center;
          background: #f2f0eb;
          border-radius: 3px;
          font-size: 12px;
          font-weight: 600;
          color: #22292f;
          line-height: 20px;
        }
      }
      span {
        display: inline-block;
        padding: 0 4px;
        height: 18px;
        font-size: 12px;
        font-weight: 400;
        color: #22292f;
        line-height: 18px;
        transform: scale(0.9);
        border-radius: 3px;
      }
      .pools-type_over_status {
        color: #42526e;
        background: #dfe1e6;
      }
      .pools-type_progress_status {
        color: #1890ff;
        background: #d4e9e2;
      }
      .pools-type_coming_status {
        color: #22292f;
        background: #fff0b3;
      }
      .pools-type_card_ratio {
        margin-top: 14px;
        margin-bottom: 0;
        font-size: 14px;
        // font-weight: bold;
        color: #22292f;
        line-height: 14px;
        i {
          margin-top: 10px;
          display: block;
          font-style: normal;
          font-size: 12px;
          color: #22292f;
          line-height: 12px;
        }
      }
      .pools-type_card_access {
        // margin-top: 20px;
        line-height: 24px;
        // display: flex;
        align-items: center;
      }
      .crown,
      .tips,
      .crown-highlight {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
      .crown {
        margin-right: 2px;
        background: url('../icon/home_N02_night@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .crown-highlight {
        margin-right: 2px;
        background: url('../icon/home_N01_night@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .tips {
        margin-left: 2px;
        background: url('../icon/06\ icon／minor／info@2x.png') no-repeat;
        background-size: 100% 100%;
        &:hover {
          cursor: pointer;
        }
      }
      .tips_content {
        position: absolute;
        bottom: 30px;
        right: 0;
        padding: 0 10px;
        margin-top: 0 !important;
        height: 24px;
        font-style: normal;
        font-size: 12px;
        color: #22292f;
        line-height: 24px !important;
        background: #e1dfd9;
        border-radius: 3px;
        white-space: nowrap;
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          bottom: -5px;
          right: 6px;
          width: 0;
          height: 0;
          border-top: 7px solid #e1dfd9;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
        }
      }
    }
    &_percentage {
      a {
        margin-top: 10px;
        position: relative;
        display: block;
        width: 100%;
        height: 8px;
        background: #f2f0eb;
        border-radius: 5px;
        .pools-type_progress_bar {
          position: absolute;
          left: 0;
          top: 0;
          height: 8px;
          background: #1890ff;
          border-radius: 5px;
        }
      }
      p {
        margin-top: 4px;
        margin-bottom: 0;
        font-size: 12px;
        color: #22292f;
        line-height: 12px;
      }
    }
    &_enter {
      margin-top: 30px;
      display: inline-block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      background: #1890ff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      text-align: center;
      &:hover {
        color: #fff;
        background: #00b900;
      }
    }
    &_disable_enter {
      color: #fff;
      background: #b6b9bc;
      &:hover {
        color: #fff;
        background: #b6b9bc;
      }
    }
  }
}
@media #{$xsWidth} {
  .pools-type {
    &_big_title {
      display: block;
      margin: 20px 0 40px;
      height: 50px;
      font-size: 42px;
      color: #1890ff !important;
      line-height: 50px;
      text-align: center;
    }
    &-top {
      width: 100%;
      background: #f2f0eb;
    }
    &-bottom {
      padding: 60px 0 50px;
      width: 100%;
      text-align: center;
      background: #fff;
      h2 {
        margin-bottom: 0;
        height: 44px;
        font-size: 44px;
        font-weight: 600;
        color: #22292f;
        line-height: 44px;
      }
    }
    &-bottom_content {
      padding: 60px 0 0;
      width: 100%;
      margin: 0 auto;
      .pools-type-bottom_content_box {
        img {
          display: inline-block;
          margin-bottom: 30px;
          width: 302px;
          height: 100px;
          padding: 30px 10px;
          background: #ffffff;
          border-radius: 30px;
          border: 1px solid #e1dfd9;
          &:hover {
            cursor: pointer;
            border: 1px solid #1890ff;
          }
        }
        &:nth-child(2) {
          margin-top: 0;
        }
        &:nth-child(3) {
          margin-top: 0;
        }
      }
    }
    &_content {
      margin: 0 auto;
      padding: 0 15px 40px;
      width: 100%;
    }
    &_tab {
      display: flex;
      width: 100%;
      border-bottom: 2px solid #ebe9e3;
      .flashPool_png {
        margin-right: 4px;
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: text-top;
      }
      h2 {
        position: relative;
        margin-bottom: 0;
        margin-right: 30px;
        height: 60px;
        font-size: 12px;
        font-weight: 500;
        color: #7a7f82;
        line-height: 60px;
        &:hover {
          cursor: pointer;
        }
        &.tab_active {
          color: #22292f;
          &:after {
            position: absolute;
            content: '';
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 4px;
            background: #1890ff;
          }
        }
      }
      .new_flag {
        &:before {
          position: absolute;
          content: 'NEW';
          top: 8px;
          right: -30px;
          padding: 0 4px;
          height: 16px;
          line-height: 16px;
          color: #fff;
          font-size: 12px;
          transform: scale(0.8);
          border-radius: 3px;
          background: #f43900;
        }
      }
    }
    &_noData {
      padding-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 270px;
        height: 150px;
      }
      p {
        margin-top: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #7a7f82;
      }
    }
    &_card {
      margin-top: 10px;
      .pools-type_card_box {
        box-sizing: border-box;
        display: block;
        margin: 20px auto 0;
        padding: 30px 30px 40px 30px;
        width: 320px;
        height: 368px;
        background: #fff;
        border-radius: 20px;
      }
      .pools-type_private {
        background: #fffbfa;
      }
      .pools-type_flashPool {
        background: url('../icon/shandian@2x.png') no-repeat;
        background-position: right bottom;
        background-size: 110px 150px;
        background-color: #fffaf2;
      }
    }
    &_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // img {
      //   display: inline-block;
      //   // width: 50px;
      //   height: 50px;
      //   margin-right: 6px;
      // }
      img {
        display: inline-block;
        // width: 50px;
        // height: 50px;
        width: 100px;
        height: 40px;
        margin-right: 6px;
      }
      .pools-type_card_title {
        display: inline-block;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: bold;
        color: #22292f;
        line-height: 20px;
      }
      .pools-type_card_title_right {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .pools-type_time {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        span {
          font-style: normal;
          padding: 0 4px;
          height: 20px;
          text-align: center;
          background: #f2f0eb;
          border-radius: 3px;
          font-size: 12px;
          font-weight: 600;
          color: #22292f;
          line-height: 20px;
        }
      }
      span {
        display: inline-block;
        padding: 0 4px;
        height: 18px;
        font-size: 12px;
        font-weight: 400;
        color: #22292f;
        line-height: 18px;
        transform: scale(0.9);
        border-radius: 3px;
      }
      .pools-type_over_status {
        color: #42526e;
        background: #dfe1e6;
      }
      .pools-type_progress_status {
        color: #1890ff;
        background: #d4e9e2;
      }
      .pools-type_coming_status {
        color: #22292f;
        background: #fff0b3;
      }
      .pools-type_card_ratio {
        margin-top: 14px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: bold;
        color: #22292f;
        line-height: 14px;
        i {
          margin-top: 10px;
          display: block;
          font-style: normal;
          font-size: 12px;
          color: #22292f;
          line-height: 12px;
        }
      }
      .pools-type_card_access {
        margin-top: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
      }
      .crown,
      .tips,
      .crown-highlight {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
      .crown {
        margin-right: 2px;
        background: url('../icon/home_N02_night@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .crown-highlight {
        margin-right: 2px;
        background: url('../icon/home_N01_night@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      .tips {
        margin-left: 2px;
        background: url('../icon/06\ icon／minor／info@2x.png') no-repeat;
        background-size: 100% 100%;
        &:hover {
          cursor: pointer;
        }
      }
      .tips_content {
        position: absolute;
        bottom: 30px;
        right: 0;
        padding: 0 10px;
        margin-top: 0 !important;
        height: 24px;
        font-style: normal;
        font-size: 12px;
        color: #22292f;
        line-height: 24px !important;
        background: #e1dfd9;
        border-radius: 3px;
        white-space: nowrap;
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          bottom: -5px;
          right: 6px;
          width: 0;
          height: 0;
          border-top: 7px solid #e1dfd9;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
        }
      }
    }
    &_percentage {
      a {
        margin-top: 10px;
        position: relative;
        display: block;
        width: 100%;
        height: 8px;
        background: #f2f0eb;
        border-radius: 5px;
        .pools-type_progress_bar {
          position: absolute;
          left: 0;
          top: 0;
          height: 8px;
          background: #1890ff;
          border-radius: 5px;
        }
      }
      p {
        margin-top: 4px;
        margin-bottom: 0;
        font-size: 12px;
        color: #22292f;
        line-height: 12px;
      }
    }
    &_enter {
      margin-top: 30px;
      display: inline-block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      background: #1890ff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      text-align: center;
      &:hover {
        color: #fff;
        background: #00b900;
      }
    }
    &_disable_enter {
      color: #fff;
      background: #b6b9bc;
      &:hover {
        color: #fff;
        background: #b6b9bc;
      }
    }
  }
}
