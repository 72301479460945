.footer {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px;
  display: flex;
  width: 100%;
  height: 80px;
  &__links {
    display: flex;

    li {
      margin: 12px;
    }

    a {
      display: block;
      width: 24px;
      svg {
        fill: #7a7f82;
      }
      img {
        width: 18px;
        height: 24px;
        &:hover {
          svg {
            fill: #0f8c20;
          }
        }
      }
      @media #{$desktop} {
        &:hover svg {
          fill: #0f8c20;
        }
      }
    }
  }
}
@media #{$desktop} {
  .footer_wrap {
    width: 1280px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
@media #{$mdWidth} {
  .footer {
    display: none;
  }
  .footer_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media #{$smWidth} {
  .footer_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media #{$xsWidth} {
  .footer_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media #{$smWidth} {
  .footer {
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 1);
    padding: 12px;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    display: none;

    &__links {
      display: flex;
      align-items: center;
      li {
        margin: 12px;
      }

      a {
        display: block;
        width: 24px;
        svg {
          fill: #7a7f82;
        }
        @media #{$desktop} {
          &:hover svg {
            fill: #0f8c20;
          }
        }
      }
    }
  }
}
@media #{$xsWidth} {
  .footer {
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 1);
    padding: 12px;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    display: none;

    &__links {
      display: flex;
      align-items: center;
      li {
        margin: 12px;
      }

      a {
        display: block;
        width: 24px;
        svg {
          fill: #7a7f82;
        }
        @media #{$desktop} {
          &:hover svg {
            fill: #0f8c20;
          }
        }
      }
    }
  }
}
