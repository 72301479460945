@media #{$smWidth} { // 移动端适配
  .pool-top {
    // background: url(../image/pool.png) no-repeat;
    // background-size: 100% 100%;
    // height: 280px;
    padding-bottom: 30px;
    .imgindex {
      width: 236px;
      height: 51px;
      margin: 0 auto;
    }
    .tokens {
      width: 85%;
      // height: 30px;
      // background: rgba(18, 0, 162, 0.5);
      // border-radius: 15px;
      background: rgba(81, 193, 194, 0.4);
      border-radius: 18px;
      margin-left: 5%;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #14144E;
      opacity: 0.75;
      text-align: center;
      // line-height: 30px;
      padding: 5px 5px;
    }
  }
  .pools_card {
    .ido {
      margin-top: 10px;
      img {
        width: 236px;
        height: 40px;
        margin: 0 auto;
      }
    }
    .count-i-down {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3C26E8;
      text-align: right;
      margin-top: 20px;
    }
  }
  .raise-funds {
    margin: 0 auto 20px;
    width: 320px;
    height: 100%;
    .recode {
      width: 220px;
      height: 36px;
      background: rgba(90, 141, 255, 0.4);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 18px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3C26E8;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
    }
    .recode-list {
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      box-shadow: -8px 0px 30px 0px rgba(0, 26, 86, 0.1);
      border-radius: 12px;
      margin-top: 15px;
      .list-1 {
        width: 100%;
        // height: 39px;
        background: #ececec;
        // border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .span {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #060417;
          width: 40%;
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: center;
        }
      }
      .list-2 {
        display: flex;
        height: 61px;
        justify-content: space-around;
        align-items: center;
        .list-top {
          display: flex;
          align-items: center;
          flex-direction: column;
          .l-1 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #060417;
          }
          .l-2 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #a3a3a8;
            margin-top: 4px;
          }
        }
        .list-bottom {
          display: flex;
          align-items: center;
          flex-direction: column;
          .l-1 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #060417;
          }
          .l-2 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #a3a3a8;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
@media #{$desktop} { // pc端
  .pool-top {
    // background: url(../image/pool.png) no-repeat;
    // background-size: 100%;
    height: 500px;
    .imgindex {
      width: 436px;
      height: 111px;
      margin: 0 auto;
    }
    .tokens {
      width: 1080px;
      height: 60px;
      background: rgba(18, 0, 162, 0.5);
      
      border-radius: 15px;
      margin: 0 auto;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      opacity: 0.75;
      text-align: center;
      line-height: 60px;
    }
  }
  .pools_card {
    height: 500px;
    .pools_card_content {
      height: 500px;
      .ido {
        margin-top: 20px;
        img {
          width: 400px;
          height: 60px;
          margin: 0 auto;
        }
      }
      .count-i-down {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #3C26E8;
        text-align: right;
        margin-top: 20px;
      }
    }
  }
  .pools_detail_btn_box {
    .pools_detail_btn {
      width: 640px;
      height: 72px;
      background: rgba(90, 141, 255, 0.4);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 36px;
      line-height: 72px;
      color: #3C26E8;
      font-size: 30px;
    }
  }
  .raise-funds {
    margin: 0 auto 20px;
    width: 640px;
    height: 100%;
    margin-top: 70px;
    .recode {
      width: 400px;
      height: 72px;
      background: rgba(90, 141, 255, 0.4);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 36px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3C26E8;
      text-align: center;
      line-height: 72px;
      margin: 0 auto;
    }
    .recode-list {
      width: 100%;
      height: 200px;
      background: #FFFFFF;
      box-shadow: -8px 0px 30px 0px rgba(0, 26, 86, 0.1);
      border-radius: 12px;
      margin-top: 30px;
      .list-1 {
        width: 100%;
        height: 80px;
        background: #ececec;
        // border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        span {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #060417;
        }
      }
      .list-2 {
        display: flex;
        height: 120px;
        justify-content: space-around;
        align-items: center;
        .list-top {
          display: flex;
          align-items: center;
          flex-direction: column;
          .l-1 {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #060417;
          }
          .l-2 {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #a3a3a8;
            margin-top: 4px;
          }
        }
        .list-bottom {
          display: flex;
          align-items: center;
          flex-direction: column;
          .l-1 {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #060417;
          }
          .l-2 {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #a3a3a8;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
@media #{$mdWidth} {
  .pool-top {
    background: url(../image/pool.png) no-repeat;
    background-size: 100% 100%;
    height: 280px;
    .imgindex {
      width: 236px;
      height: 51px;
      margin: 0 auto;
    }
    .tokens {
      width: 90%;
      height: 30px;
      background: rgba(18, 0, 162, 0.5);
      border-radius: 15px;
      margin: 0 auto;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      opacity: 0.75;
      text-align: center;
      line-height: 30px;
    }
  }
  .pools_card {
    .ido {
      margin-top: 20px;
      img {
        width: 236px;
        height: 52px;
        margin: 0 auto;
      }
    }
    .count-i-down {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3C26E8;
      text-align: right;
      margin-top: 20px;
    }
  }
  .raise-funds {
    margin: 0 auto 20px;
    width: 320px;
    height: 100%;
    .recode {
      width: 220px;
      height: 36px;
      background: rgba(90, 141, 255, 0.4);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 18px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3C26E8;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
    }
    .recode-list {
      width: 100%;
      height: 100px;
      background: #FFFFFF;
      box-shadow: -8px 0px 30px 0px rgba(0, 26, 86, 0.1);
      border-radius: 12px;
      margin-top: 15px;
      .list-1 {
        width: 100%;
        height: 39px;
        background: #ececec;
        // border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        span {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #060417;
        }
      }
      .list-2 {
        display: flex;
        height: 61px;
        justify-content: space-around;
        align-items: center;
        .list-top {
          display: flex;
          align-items: center;
          flex-direction: column;
          .l-1 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #060417;
          }
          .l-2 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #a3a3a8;
            margin-top: 4px;
          }
        }
        .list-bottom {
          display: flex;
          align-items: center;
          flex-direction: column;
          .l-1 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #060417;
          }
          .l-2 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #a3a3a8;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
@media #{$xsWidth} {
  .pool-top {
    background: url(../image/pool.png) no-repeat;
    background-size: 100% 100%;
    height: 280px;
    .imgindex {
      width: 236px;
      height: 51px;
      margin: 0 auto;
    }
    .tokens {
      width: 90%;
      height: 30px;
      background: rgba(18, 0, 162, 0.5);
      border-radius: 15px;
      margin: 0 auto;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      opacity: 0.75;
      text-align: center;
      line-height: 30px;
    }
  }
  .pools_card {
    .ido {
      margin-top: 20px;
      img {
        width: 236px;
        height: 52px;
        margin: 0 auto;
      }
    }
    .count-i-down {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3C26E8;
      text-align: right;
      margin-top: 20px;
    }
  }
  .raise-funds {
    margin: 0 auto 20px;
    width: 320px;
    height: 100%;
    .recode {
      width: 220px;
      height: 36px;
      background: rgba(90, 141, 255, 0.4);
      box-shadow: 2px 6px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 18px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3C26E8;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
    }
    .recode-list {
      width: 100%;
      height: 100px;
      background: #FFFFFF;
      box-shadow: -8px 0px 30px 0px rgba(0, 26, 86, 0.1);
      border-radius: 12px;
      margin-top: 15px;
      .list-1 {
        width: 100%;
        height: 39px;
        background: #ececec;
        // border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        span {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #060417;
        }
      }
      .list-2 {
        display: flex;
        height: 61px;
        justify-content: space-around;
        align-items: center;
        .list-top {
          display: flex;
          align-items: center;
          flex-direction: column;
          .l-1 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #060417;
          }
          .l-2 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #a3a3a8;
            margin-top: 4px;
          }
        }
        .list-bottom {
          display: flex;
          align-items: center;
          flex-direction: column;
          .l-1 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #060417;
          }
          .l-2 {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #a3a3a8;
            margin-top: 4px;
          }
        }
      }
    }
  }
}