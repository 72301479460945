.menu {
  white-space: nowrap;

  &__list {
    display: flex;
    justify-content: center;
    margin-bottom: 0;

    @media #{$mdWidth} {
      display: block;
    }
  }

  &__item {
    margin-right: 30px;

    @media #{$mdWidth} {
      margin: 36px 0;
    }
  }

  &__link {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: inherit;
    display: block;
    transform: var(--transitionDefault);

    &.is-current {
      color: rgba(15, 140, 32, 1);
      pointer-events: none;
    }

    &.router-link-exact-active,
    &.router-link-active {
      color: var(--yellow);
      pointer-events: none;
    }

    @media #{$desktop} {
      &:hover {
        color: color(var(--color) a(50%));
      }
    }

    @media #{$mdWidth} {
      font-size: 20px;
      text-align: center;
    }
  }
  &__hot {
    position: absolute;
    top: -22px;
    right: -34px;
    width: 46px;
    height: 26px;
    background: url('../icon/HOT@2x.png') no-repeat;
    background-size: 100% 100%;
  }
}
