:root {
    --color: rgba(34, 41, 47, 1);
    --text: color(#fff a(70%));
    --error: #f45959;
    --bg: rgba(242, 240, 235, 1);
    --blue: blue;
    --border: color(#fad06a a(20%));
    --green: #86ba6e;
    --link: #fd7749;
    --red: #fa6a6a;
    --yellow: #fad06a;
    --yellowHover: #e4be62;
    --yellowDisabled: color(#fad06a a(30%));
    --transitionDefault: 0.3s;
}

$desktop: '(min-width: 1200px)';
$mdWidth: '(min-width:747px) and (max-width: 1199px)';
$smWidth: '(min-width:359px)and (max-width: 747px)';
$xsWidth: '(max-width: 359px)';
