.pools-index {
  min-width: 1200px;
  background: #fff;
}
@media #{$desktop} {
  .pools-index {
    &_content {
      padding-bottom: 40px;
      margin: 0 auto;
      width: 1200px;
    }
    &_tab {
      height: 60px;
      line-height: 60px;
      border-bottom: 2px solid #ebe9e3;
      a {
        position: relative;
        display: inline-block;
        margin-right: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        &.active {
          color: #22292f;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background-color: #0f8c20;
          }
        }
      }
    }
    &_search {
      position: relative;
      margin: 20px auto;
      width: 100%;
      height: 50px;
      line-height: 50px;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      &:after {
        content: '';
        position: absolute;
        top: 13px;
        left: 16px;
        z-index: 1;
        width: 24px;
        height: 24px;
        background: url('../icon/search-eye-line@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      input {
        padding: 0 48px;
        width: 100%;
        height: 100%;
        line-height: 100%;
        font-size: 16px;
        color: #22292f;
        border: none;
        outline: none;
        border-radius: 5px;
        vertical-align: top;
        &::-webkit-input-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &:-moz-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &::-moz-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &:-ms-input-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
      }
    }
    &_table {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      thead {
        tr {
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #ebe9e3;
        }
        td {
          font-size: 14px;
          font-weight: bold;
          color: #22292f;
          line-height: 24px;
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            padding-right: 30px;
            text-align: right;
          }
        }
        .pools-index_progress {
          width: 260px;
        }
      }
      tbody {
        tr {
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #ebe9e3;
          &:hover {
            cursor: pointer;
            background-color: #f5fbf9;
          }
        }
        td {
          font-size: 14px;
          color: #22292f;
          line-height: 24px;
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            padding-right: 30px;
            font-size: 12px;

            border-radius: 3px;
            text-align: right;
          }
          .pools-index_list_logo {
            display: inline-block;
            margin-right: 8px;
            width: 24px;
            height: 24px;
            background: url('../icon/WAR@2x.png') no-repeat;
            background-size: 100% 100%;
            vertical-align: middle;
          }
          .pools-index_ratio {
            display: inline-block;
            width: 40px;
          }
          p {
            display: inline-block;
            padding: 0 4px;
            height: 18px;
            line-height: 18px;
            transform: scale(0.9);
          }
          .pools-index_over_status {
            color: #42526e;
            background: #dfe1e6;
          }
          .pools-index_progress_status {
            color: #0f8c20;
            background: #d4e9e2;
          }
          .pools-index_coming_status {
            color: #22292f;
            background: #fff0b3;
          }
          a {
            position: relative;
            display: inline-block;
            width: 200px;
            height: 8px;
            background: #f2f0eb;
            border-radius: 5px;
          }
          .pools-index_progress_bar {
            position: absolute;
            left: 0;
            top: 0;
            height: 8px;
            background: #0f8c20;
            border-radius: 5px;
          }
        }
        .pools-index_noData {
          padding: 30px 0;
          font-size: 20px !important;
          color: #7a7f82;
          text-align: center !important;
        }
      }
    }
    .ant-pagination {
      margin: 30px auto 0;
      text-align: center;
    }
    .ant-pagination-item {
      border: none;
    }
    .ant-pagination-item:focus,
    .ant-pagination-item:hover {
      color: #22292f;
      background: #ededf0;
      border-color: #ededf0;
    }
    .ant-pagination-item:focus a,
    .ant-pagination-item:hover a {
      color: #22292f;
    }
    .ant-pagination-item-active {
      background: #ededf0;
      border-color: #ededf0;
    }
    .ant-pagination-item-active a {
      color: #22292f;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border: none;
    }
  }
}
@media #{$mdWidth} {
  .pools-index {
    &_content {
      padding-bottom: 40px;
      margin: 0 auto;
      width: 1200px;
    }
    &_tab {
      height: 60px;
      line-height: 60px;
      border-bottom: 2px solid #ebe9e3;
      a {
        position: relative;
        display: inline-block;
        margin-right: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        &.active {
          color: #22292f;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background-color: #0f8c20;
          }
        }
      }
    }
    &_search {
      position: relative;
      margin: 20px auto;
      width: 100%;
      height: 50px;
      line-height: 50px;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      &:after {
        content: '';
        position: absolute;
        top: 13px;
        left: 16px;
        z-index: 1;
        width: 24px;
        height: 24px;
        background: url('../icon/search-eye-line@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      input {
        padding: 0 48px;
        width: 100%;
        height: 100%;
        line-height: 100%;
        font-size: 16px;
        color: #22292f;
        border: none;
        outline: none;
        border-radius: 5px;
        vertical-align: top;
        &::-webkit-input-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &:-moz-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &::-moz-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &:-ms-input-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
      }
    }
    &_table {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      thead {
        tr {
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #ebe9e3;
        }
        td {
          font-size: 14px;
          font-weight: bold;
          color: #22292f;
          line-height: 24px;
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            padding-right: 30px;
            text-align: right;
          }
        }
        .pools-index_progress {
          width: 260px;
        }
      }
      tbody {
        tr {
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #ebe9e3;
          &:hover {
            cursor: pointer;
            background-color: #f5fbf9;
          }
        }
        td {
          font-size: 14px;
          color: #22292f;
          line-height: 24px;
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            padding-right: 30px;
            font-size: 12px;

            border-radius: 3px;
            text-align: right;
          }
          .pools-index_list_logo {
            display: inline-block;
            margin-right: 8px;
            width: 24px;
            height: 24px;
            background: url('../icon/WAR@2x.png') no-repeat;
            background-size: 100% 100%;
            vertical-align: middle;
          }
          .pools-index_ratio {
            display: inline-block;
            width: 40px;
          }
          p {
            display: inline-block;
            padding: 0 4px;
            height: 18px;
            line-height: 18px;
            transform: scale(0.9);
          }
          .pools-index_over_status {
            color: #42526e;
            background: #dfe1e6;
          }
          .pools-index_progress_status {
            color: #0f8c20;
            background: #d4e9e2;
          }
          .pools-index_coming_status {
            color: #22292f;
            background: #fff0b3;
          }
          a {
            position: relative;
            display: inline-block;
            width: 200px;
            height: 8px;
            background: #f2f0eb;
            border-radius: 5px;
          }
          .pools-index_progress_bar {
            position: absolute;
            left: 0;
            top: 0;
            height: 8px;
            background: #0f8c20;
            border-radius: 5px;
          }
        }
        .pools-index_noData {
          padding: 30px 0;
          font-size: 20px !important;
          color: #7a7f82;
          text-align: center !important;
        }
      }
    }
    .ant-pagination {
      margin: 30px auto 0;
      text-align: center;
    }
    .ant-pagination-item {
      border: none;
    }
    .ant-pagination-item:focus,
    .ant-pagination-item:hover {
      color: #22292f;
      background: #ededf0;
      border-color: #ededf0;
    }
    .ant-pagination-item:focus a,
    .ant-pagination-item:hover a {
      color: #22292f;
    }
    .ant-pagination-item-active {
      background: #ededf0;
      border-color: #ededf0;
    }
    .ant-pagination-item-active a {
      color: #22292f;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border: none;
    }
  }
}
@media #{$smWidth} {
  .pools-index {
    &_content {
      padding-bottom: 40px;
      margin: 0 auto;
      width: 1200px;
    }
    &_tab {
      height: 60px;
      line-height: 60px;
      border-bottom: 2px solid #ebe9e3;
      a {
        position: relative;
        display: inline-block;
        margin-right: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        &.active {
          color: #22292f;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            width: 100%;
            height: 4px;
            background-color: #0f8c20;
          }
        }
      }
    }
    &_search {
      position: relative;
      margin: 20px auto;
      width: 100%;
      height: 50px;
      line-height: 50px;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      &:after {
        content: '';
        position: absolute;
        top: 13px;
        left: 16px;
        z-index: 1;
        width: 24px;
        height: 24px;
        background: url('../icon/search-eye-line@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      input {
        padding: 0 48px;
        width: 100%;
        height: 100%;
        line-height: 100%;
        font-size: 16px;
        color: #22292f;
        border: none;
        outline: none;
        border-radius: 5px;
        vertical-align: top;
        &::-webkit-input-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &:-moz-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &::-moz-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &:-ms-input-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
      }
    }
    &_table {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      thead {
        tr {
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #ebe9e3;
        }
        td {
          font-size: 14px;
          font-weight: bold;
          color: #22292f;
          line-height: 24px;
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            padding-right: 30px;
            text-align: right;
          }
        }
        .pools-index_progress {
          width: 260px;
        }
      }
      tbody {
        tr {
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #ebe9e3;
          &:hover {
            cursor: pointer;
            background-color: #f5fbf9;
          }
        }
        td {
          font-size: 14px;
          color: #22292f;
          line-height: 24px;
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            padding-right: 30px;
            font-size: 12px;

            border-radius: 3px;
            text-align: right;
          }
          .pools-index_list_logo {
            display: inline-block;
            margin-right: 8px;
            width: 24px;
            height: 24px;
            background: url('../icon/WAR@2x.png') no-repeat;
            background-size: 100% 100%;
            vertical-align: middle;
          }
          .pools-index_ratio {
            display: inline-block;
            width: 40px;
          }
          p {
            display: inline-block;
            padding: 0 4px;
            height: 18px;
            line-height: 18px;
            transform: scale(0.9);
          }
          .pools-index_over_status {
            color: #42526e;
            background: #dfe1e6;
          }
          .pools-index_progress_status {
            color: #0f8c20;
            background: #d4e9e2;
          }
          .pools-index_coming_status {
            color: #22292f;
            background: #fff0b3;
          }
          a {
            position: relative;
            display: inline-block;
            width: 200px;
            height: 8px;
            background: #f2f0eb;
            border-radius: 5px;
          }
          .pools-index_progress_bar {
            position: absolute;
            left: 0;
            top: 0;
            height: 8px;
            background: #0f8c20;
            border-radius: 5px;
          }
        }
        .pools-index_noData {
          padding: 30px 0;
          font-size: 20px !important;
          color: #7a7f82;
          text-align: center !important;
        }
      }
    }
    .ant-pagination {
      margin: 30px auto 0;
      text-align: center;
    }
    .ant-pagination-item {
      border: none;
    }
    .ant-pagination-item:focus,
    .ant-pagination-item:hover {
      color: #22292f;
      background: #ededf0;
      border-color: #ededf0;
    }
    .ant-pagination-item:focus a,
    .ant-pagination-item:hover a {
      color: #22292f;
    }
    .ant-pagination-item-active {
      background: #ededf0;
      border-color: #ededf0;
    }
    .ant-pagination-item-active a {
      color: #22292f;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border: none;
    }
  }
}
@media #{$xsWidth} {
  .pools-index {
    &_content {
      padding-bottom: 40px;
      margin: 0 auto;
      width: 1200px;
    }
    &_tab {
      height: 60px;
      line-height: 60px;
      border-bottom: 2px solid #ebe9e3;
      a {
        position: relative;
        display: inline-block;
        margin-right: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #7a7f82;
        &.active {
          color: #22292f;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            width: 100%;
            height: 4px;
            background-color: #0f8c20;
          }
        }
      }
    }
    &_search {
      position: relative;
      margin: 20px auto;
      width: 100%;
      height: 50px;
      line-height: 50px;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      &:after {
        content: '';
        position: absolute;
        top: 13px;
        left: 16px;
        z-index: 1;
        width: 24px;
        height: 24px;
        background: url('../icon/search-eye-line@2x.png') no-repeat;
        background-size: 100% 100%;
      }
      input {
        padding: 0 48px;
        width: 100%;
        height: 100%;
        line-height: 100%;
        font-size: 16px;
        color: #22292f;
        border: none;
        outline: none;
        border-radius: 5px;
        vertical-align: top;
        &::-webkit-input-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &:-moz-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &::-moz-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
        &:-ms-input-placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #b6b9bc;
        }
      }
    }
    &_table {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #ebe9e3;
      thead {
        tr {
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #ebe9e3;
        }
        td {
          font-size: 14px;
          font-weight: bold;
          color: #22292f;
          line-height: 24px;
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            padding-right: 30px;
            text-align: right;
          }
        }
        .pools-index_progress {
          width: 260px;
        }
      }
      tbody {
        tr {
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #ebe9e3;
          &:hover {
            cursor: pointer;
            background-color: #f5fbf9;
          }
        }
        td {
          font-size: 14px;
          color: #22292f;
          line-height: 24px;
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            padding-right: 30px;
            font-size: 12px;

            border-radius: 3px;
            text-align: right;
          }
          .pools-index_list_logo {
            display: inline-block;
            margin-right: 8px;
            width: 24px;
            height: 24px;
            background: url('../icon/WAR@2x.png') no-repeat;
            background-size: 100% 100%;
            vertical-align: middle;
          }
          .pools-index_ratio {
            display: inline-block;
            width: 40px;
          }
          p {
            display: inline-block;
            padding: 0 4px;
            height: 18px;
            line-height: 18px;
            transform: scale(0.9);
          }
          .pools-index_over_status {
            color: #42526e;
            background: #dfe1e6;
          }
          .pools-index_progress_status {
            color: #0f8c20;
            background: #d4e9e2;
          }
          .pools-index_coming_status {
            color: #22292f;
            background: #fff0b3;
          }
          a {
            position: relative;
            display: inline-block;
            width: 200px;
            height: 8px;
            background: #f2f0eb;
            border-radius: 5px;
          }
          .pools-index_progress_bar {
            position: absolute;
            left: 0;
            top: 0;
            height: 8px;
            background: #0f8c20;
            border-radius: 5px;
          }
        }
        .pools-index_noData {
          padding: 30px 0;
          font-size: 20px !important;
          color: #7a7f82;
          text-align: center !important;
        }
      }
    }
    .ant-pagination {
      margin: 30px auto 0;
      text-align: center;
    }
    .ant-pagination-item {
      border: none;
    }
    .ant-pagination-item:focus,
    .ant-pagination-item:hover {
      color: #22292f;
      background: #ededf0;
      border-color: #ededf0;
    }
    .ant-pagination-item:focus a,
    .ant-pagination-item:hover a {
      color: #22292f;
    }
    .ant-pagination-item-active {
      background: #ededf0;
      border-color: #ededf0;
    }
    .ant-pagination-item-active a {
      color: #22292f;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border: none;
    }
  }
}
