@media #{$smWidth} { // 移动端适配
  .titleHeader {
    font-size: 34px;
    font-family: DIN Alternate;
    font-weight: bold;
    color: #4EC3C0;
    -webkit-text-stroke: 1px #13134D;
    text-stroke: 1px #13134D;
    background: linear-gradient(0deg, #19DC9F 0%, #FDE980 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // box-shadow: 0px 0px 10px 0px rgba(0, 51, 44, 0.2);
    // text-shadow: 4px 4px 4px rgba(0, 51, 44, 0.5);
    padding-left: 20px;
    margin-top: 50px;
    span {
      margin-left: 10px;
      font-size: 26px;
    }
  }
  .conversion {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    .left {
      background: linear-gradient(90deg, #E5C83B, #FDE980);
      border-radius: 36px;
      padding: 10px 20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #14144E;
    }
    .right {
      background: linear-gradient(90deg, #E5C83B, #FDE980);
      border-radius: 36px;
      padding: 10px 20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #14144E;
    }
  }
  .inregardto {
    margin-left: 20px;
    width: 120px;
    height: 160px;
    background: url(../image/index/gy.png) no-repeat;
    background-size: 100% 100%;
    .inido {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #14144E;
      text-align: center;
      padding-top: 20px;
      span {
        font-size: 26px;
      }
    }
  }
  .jies {
    width: 66%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 51, 44, 0.2);
    border-radius: 24px;
    display: flex;
    margin-left: 29%;
    margin-top: -60px;
    padding: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #14144E;
    line-height: 20px;
    opacity: 0.8;
  }
  .gathers {
    display: flex;
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
    .left {
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 51, 44, 0.2);
      border-radius: 22px;
      display: flex;
      align-items: center;
      padding: 0px 10px;
      height: 40px;
      line-height: 40px;
      width: 65%;
      img {
        width: 22px;
        height: 22px;
      }
      span {
        margin-left: 10px;
        width: 80%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
    .right {
      width: 35%;
      background: url(../../assets/image/index/zcdz.png) no-repeat;
      background-size: 100% 100%;
      height: 180px;
      // img {
      //   width: 100%;
      //   height: 180px;
      // }
      .dzs {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #14144E;
        text-align: center;
        width: 100%;
        padding: 0px 10px;
        margin-top: 20px;
        word-wrap:break-word;
        span {
          font-size: 20px;
        }
      }
    }
  }
  .ewxzsd {
    width: 180px;
    height: 180px;
    background: #12B8A0;
    border-radius: 24px;
    margin-left: 20px;
    margin-top: -90px;
    display: flex;
    justify-Content: center;
    align-items: center
  }
  .wdewm {
    width: 150px;
    height: 30px;
    background: #F2DA61;
    border-radius: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #14144E;
    margin: 0 auto;
    text-align: center;
    line-height: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .gzsaxc {
    width: 92%;
    margin: 0 auto;
    height: 100%;
    background: rgba(25, 202, 176, 0.05);
    box-shadow: 0px 0px 20px 0px rgba(0, 51, 44, 0.2);
    border-radius: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
    .vent {
      width: 92%;
      margin: 0 auto;
      .gas-1 {
        width: 90px;
        height: 36px;
        background: linear-gradient(90deg, #E5C83B, #FDE980);
        border-radius: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #14144E;
        text-align: center;
        line-height: 36px;
      }
      .div-c {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #14144E;
      }
    }
  }
  .xec-index {
    width: 150px;
    height: 36px;
    background: #19CAB0;
    border-radius: 18px;
    line-height: 36px;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
  }
  .ineex-iii {
    display: flex;
    justify-content: flex-end;
    width: 92%;
    margin: 0 auto;
    margin-top: -20px;
    img {
      width: 23px;
      height: 40px;
    }
    span {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      text-decoration: underline;
      color: #14144E;
      margin-top: 25px;
    }
  }
  .ddd-lll {
    width: 92%;
    margin: 0 auto;
    padding: 20px;
    background: rgba(25, 202, 176, 0.2);
    border-radius: 12px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #14144E;
  }
}
@media #{$desktop} { // pc端
  .titleHeader {
    font-size: 60px;
    font-family: DIN Alternate;
    font-weight: bold;
    color: #4EC3C0;
    -webkit-text-stroke: 1px #13134D;
    text-stroke: 1px #13134D;
    background: linear-gradient(0deg, #19DC9F 0%, #FDE980 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // box-shadow: 0px 0px 10px 0px rgba(0, 51, 44, 0.2);
    // text-shadow: 4px 4px 4px rgba(0, 51, 44, 0.5);
    padding-left: 20px;
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    span {
      margin-left: 10px;
      font-size: 26px;
    }
  }
  .conversion {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    .left {
      background: linear-gradient(90deg, #E5C83B, #FDE980);
      border-radius: 36px;
      padding: 10px 20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #14144E;
    }
    .right {
      background: linear-gradient(90deg, #E5C83B, #FDE980);
      border-radius: 36px;
      padding: 10px 20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #14144E;
    }
  }
  .inregardto {
    margin-left: 20px;
    width: 200px;
    height: 300px;
    background: url(../image/index/gy.png) no-repeat;
    background-size: 100% 100%;
    margin-left: 200px;
    .inido {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #14144E;
      text-align: center;
      padding-top: 50px;
      span {
        font-size: 40px;
      }
    }
  }
  .jies {
    width: 66%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 51, 44, 0.2);
    border-radius: 24px;
    display: flex;
    margin-left: 29%;
    margin-top: -60px;
    padding: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #14144E;
    line-height: 20px;
    opacity: 0.8;
  }
  .gathers {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    .left {
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 51, 44, 0.2);
      border-radius: 22px;
      display: flex;
      align-items: center;
      padding: 0px 10px;
      height: 80px;
      line-height: 80px;
      width: 65%;
      img {
        width: 42px;
        height: 42px;
      }
      span {
        margin-left: 10px;
        width: 80%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-size: 36px;
      }
    }
    .right {
      width: 35%;
      background: url(../../assets/image/index/zcdz.png) no-repeat;
      background-size: 100% 100%;
      height: 480px;
      // img {
      //   width: 100%;
      //   height: 180px;
      // }
      .dzs {
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #14144E;
        text-align: center;
        width: 100%;
        padding: 0px 10px;
        margin-top: 50px;
        span {
          font-size: 20px;
        }
      }
    }
  }
  .ewxzsd {
    margin-left: 200px;
    width: 180px;
    height: 180px;
    background: #12B8A0;
    border-radius: 24px;
    // margin-left: 20px;
    margin-top: -90px;
    display: flex;
    justify-Content: center;
    align-items: center
  }
  .wdewm {
    width: 300px;
    height: 80px;
    background: #F2DA61;
    border-radius: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #14144E;
    margin: 0 auto;
    text-align: center;
    line-height: 80px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 40px;
  }
  .gzsaxc {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    background: rgba(25, 202, 176, 0.05);
    box-shadow: 0px 0px 20px 0px rgba(0, 51, 44, 0.2);
    border-radius: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
    .vent {
      width: 92%;
      margin: 0 auto;
      .gas-1 {
        width: 90px;
        height: 36px;
        background: linear-gradient(90deg, #E5C83B, #FDE980);
        border-radius: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #14144E;
        text-align: center;
        line-height: 36px;
      }
      .div-c {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #14144E;
      }
    }
  }
  .xec-index {
    width: 150px;
    height: 36px;
    background: #19CAB0;
    border-radius: 18px;
    line-height: 36px;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
  }
  .ineex-iii {
    display: flex;
    justify-content: flex-end;
    width: 1200px;
    margin: 0 auto;
    margin-top: -20px;
    img {
      width: 180px;
      height: 240px;
    }
    span {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 500;
      text-decoration: underline;
      color: #14144E;
      margin-top: 16%;
    }
  }
  .ddd-lll {
    width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: rgba(25, 202, 176, 0.2);
    border-radius: 12px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #14144E;
  }
}
@media #{$mdWidth} {
  
}
@media #{$xsWidth} {
  

}