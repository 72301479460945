.card-pool {
    $self: &;

	margin: 20px 0;

	&__list {

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: -18px;

		@media #{$smWidth} {

			display: block;
			margin: 0;

		}

	}

	&__item {

		background-color: #fff;
		box-shadow: 0px 4px 34px rgba(31, 31, 31, 0.13);
		border-radius: 12px;
		width: 336px;
		margin: 18px;
        padding: 36px;
        
        &--big {
            display: flex;
            justify-content: space-between;
            width: 672px;
            margin: 0 auto 72px;

            #{$self}__img {
                height: 330px;
                margin: 0;

                &::after {
                    position: absolute;
                    top: -6px;
                    left: -6px;
                    right: -24px;
                    bottom: -24px;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg width='284' height='350' viewBox='0 0 284 350' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Crect x='8' y='8' width='260' height='326' stroke='black' stroke-width='4'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='284' height='350' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='4' dy='4'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");                
                    background-repeat: no-repeat;
                }
                
                @media #{$smWidth} {
                    margin: 0 auto 43px;
                }
            }

            #{$self}__title {
                text-align: left;

                @media #{$smWidth} {
                    text-align: center;
                }
            }

            #{$self}__content {
                width: 264px;

                @media #{$smWidth} {
                    width: auto;
                }
            }

            @media #{$smWidth} {
                display: block;
                width: 336px;
            }

        }

		@media #{$smWidth} {

			max-width: 100%;
			padding: 20px;
			margin: 18px auto;

		}

		@media #{$xsWidth} {

			padding: 16px;

		}


	}

	&__img {

		position: relative;
		margin: 0 auto 43px;
		display: block;
		color: #c4c4c4;
		width: 264px;
		height: 170px;
		background-color: #c4c4c4;

		@media #{$smWidth} {

			margin-bottom: 36px;

		}

		&::after {

			position: absolute;
			top: -24px;
			left: -24px;
			right: -24px;
			bottom: -24px;
			content: '';
			background-image: url("data:image/svg+xml,%3Csvg width='312' height='218' viewBox='0 0 312 218' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M277 24H24v170h265V24h-12zm0 12H36v146h241V36z' fill='%23fff'/%3E%3C/g%3E%3Cpath fill='%230F0F0F' d='M24 24h4v170h-4zM285 24h4v170h-4z'/%3E%3Cpath fill='%230F0F0F' d='M26 24h263v4H26zM26 190h263v4H26z'/%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='18' y='18' width='285' height='190' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='4' dy='4'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");

		}

	}

	&__label {

		position: absolute;
		width: 60px;
		height: 60px;
		top: -18px;
		right: -18px;
		text-align: center;
		line-height: 48px;
		font-weight: 600;
		font-size: 14px;
		color: #fff;
		border-radius: 50%;
		background-color: var(--link);
		border: 6px solid #fff;
		z-index: 2;

		@media #{$xsWidth} {

			top: -16px;

		}

	}

	&__title {

		margin: 19px 0;
		color: #0f0f0f;
		text-align: center;

	}

	&__stake {
		text-align: left;
		display: block;
		padding: 0 24px;
		line-height: 59px;
		background-color: #ffe9be;
		border-radius: 8px;
		font-size: 16px;
		color: #010101;
		position: relative;

		b {
			margin-left: 4px;
			font-weight: 600;
			font-size: 20px;

		}

		svg {

			fill: #000;
			stroke: #000;
			position: absolute;
			top: 0;
			right: 24px;
			bottom: 0;
			margin: auto;

		}

	}

	&__earn {

		text-align: center;
		margin: 13px 0;
		font-size: 16px;
		color: #000;
		opacity: 0.6;

	}

	hr {

		background-color: #c4c4c4;
		opacity: .2;

	}

	&__dl {

		margin: 15px 0 0;

		&-row {

			display: flex;
			justify-content: space-between;
			white-space: nowrap;
			margin-top: 8px;
			font-size: 14px;
			color: color(#000 a(50%)) !important;

		}

		&-dt {
			color: rgba(0,0,0,.5);
		}

		&-black {

			color: #000;

		}

		&-blue {

			color: #6dc1d3;

		}

		&-green {

			color: var(--green);

        }
        
        &-yellow {

			color: #dad06a;

		}

	}

}